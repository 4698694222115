import React from 'react';
import clss from 'classnames';
// import { useLocation } from 'react-router-dom';
// import ReactGA from 'react-ga';
import { Col, Container, Row, Tabs, Tab } from 'react-bootstrap';
import LoanApplication from '../../components/help/LoanApplication';
import './faq.css';
import Repayment from '../../components/help/Repayment';
import Account from '../../components/help/Account';
import InterestCalculation from '../../components/help/InterestCalculation';
import { useTranslation } from 'react-i18next';
// import {LanguageContext} from '../../context/LanguageContext';

function Faq({ rowClassName }) {
    const { t } = useTranslation();
    // const { language } = useContext(LanguageContext);

    return (
        <>
            <Container>
                <Row className={clss("promotions-margin justify-content-center", rowClassName)}>
                    <Col md={9} sm={12} className="tabsArea">
                        <h2 className="text-center faqHead">{t('faq.title')}</h2>
                            <div   className="faq-tabs">
                        <Tabs
                            defaultActiveKey="Loan Application"
                            transition={true}
                            id="Loan Applicatione"
                            className="mb-3"
                            fill
                        >
                            <Tab eventKey="Loan Application" title={t('faq.loan')}>
                               <LoanApplication />
                            </Tab>
                            <Tab eventKey="Repayment" title={t('faq.repayment')}>
                                <Repayment />
                            </Tab>
                            <Tab eventKey="Account" title={t('faq.account')}>
                                <Account />
                            </Tab>
                            <Tab eventKey="Interest Calculations" title={t('faq.calculation')}>
                                <InterestCalculation />
                            </Tab>
                        </Tabs>
                    </div>
                    </Col> 
                </Row>
            </Container>
        </>
    )
}

export default Faq
