import React, { useContext, useState, useEffect } from 'react';
import "./home.css";
import { Container, Row, Col } from 'react-bootstrap';
import Feature from '../../components/features/Feature';
import homescreen from './images/homeScreen.png';
import homescreenCn from './images/homeScreenCn.png';
import lockIcon from './images/lockIcon.svg';
import bulbIcon from './images/bulbIcon.svg';
import barIcon from './images/barIcon.svg';
import phoneIcon from './images/phoneIcon.svg';
import greyArrow from './images/greyArrow.svg';
import whiteArrow from './images/whiteArrow.svg';
import cash from './images/cash.png';
import bar from './images/bar.png';
import image1 from './images/image1.svg';
import image2 from './images/image2.svg';
import image3 from './images/image3.svg';
import image4 from './images/image4.svg';
import image5 from './images/image5.svg';
import image6 from './images/image6.svg';
import image7 from './images/image7.svg';
import image8 from './images/image8.svg';
import image9 from './images/image9.svg';
import image10 from './images/image10.svg';
import image11 from './images/image11.svg';
import image12 from './images/image12.svg';
import appleImg from './images/appleImg.svg';
import googleImg from './images/googleImg.svg';
import awardYear from './images/awardYear.svg';
import awardYear2 from './images/awardYear2.svg';
import awardYear4 from './images/awardYear4.svg';
import awardYear5 from './images/awardYear5.svg';
import awardYear6 from './images/awardYear6.svg';
import brandAwards from './images/brandAwards.svg';
import GetStarted from '../../components/getstarted/GetStarted';
import HomepageBannerImage from './images/HomepageBannerImage.png';
import HomepageBannerImageCn from './images/HomepageBannerImageCn.png';
import CardCarousel from '../../components/cardcarousel/CardCarousel';
import { useTranslation } from 'react-i18next';
import Number from '../../components/number/Number';
import { LanguageContext } from '../../context/LanguageContext';
import ReactMarkdown from 'react-markdown';
// import ThankuModal from '../../components/thankumodal/ThankuModal';
import HomeSlider from '../../components/homeslider/HomeSlider';
import LogoSlider from '../../components/logoslider/LogoSlider';
import { useNavigate } from "react-router-dom";
import SEO from '../../SEO';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function Home() {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  // const [modalShow, setModalShow] = useState(false);
  // const [deepLink, setLink] = useState('');
  // const [smallScreen, setSmallScreen] = useState(false);
  // const [cardImg, setCardImg] = useState('');
  const [carousel, setCarousel] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const lang = language && language === 'zh' ? 'zh-Hant-HK' : 'en';
  const navigate = useNavigate();

  const imageArray = [image1, image2, image3, image4, image5, image6, image12, image7, image8, image9, image10, image11, image12];
  const hket = 'https://topick.hket.com/article/2507035/Fintech%E6%89%93%E7%A0%B4%E5%A3%81%E5%A3%98%20%E5%88%9D%E5%89%B5%E5%80%9F%E8%B2%B8Grantit%20Apps%20%E9%9D%A0%E7%B4%B0%E5%BF%83%E8%B4%8F%E5%AE%A2%E5%BF%83';
  const yahoo = '';
  const ctjobs = 'https://www.ctgoodjobs.hk/article/%E4%BA%BA%E7%89%A9%E5%B0%88%E8%A8%AA-27%E6%AD%B2%E5[%E2%80%A6]%8B%99%E7%99%BC%E5%B1%95fintech%E4%BA%8B%E6%A5%AD/1229-23820';
  const hkej = 'https://www2.hkej.com/instantnews/hongkong/article/3238957/Grantit%E6%8E%A8G%E5%8B%95%E7%AE%A1%E5%AE%B6%E8%B2%A1%E5%8B%99%E7%AE%A1%E7%90%86%E6%9C%8D%E5%8B%99';
  const now = 'https://finance.now.com/news/post.php?id=752804';
  const infocast = 'http://www.infocastfn.com/fn/ajax/news/newsDetail?newsId=3928734';
  const quamnet = 'https://www.quamnet.com/post/O4W4rYH79dCOnQT1DTphB';
  const icon = 'https://www.01icon.hk/article/1683542/%E5%88%9D%E5%89%B5grantit%E6%89%8B%E6%A9%9F%E6%87%89%E7%94%A8%E7%A8%8B%E5%BC%8F%E6%8E%A8%E6%96%B0%E5%8A%9F%E8%83%BD-%E5%8A%A9%E7%AE%A1%E7%90%86%E4%BF%A1%E7%94%A8%E5%8D%A1';
  const emptyLink = '';
  const binnovator = 'https://www.biz-innovator.com/2023/07/26/grantit/';

  const imageLinksArray = [hket, emptyLink, yahoo, emptyLink, ctjobs, emptyLink, binnovator, hkej, now, infocast, quamnet, icon];
  // let location = useLocation();

  // useEffect(() => {
    // const { pathname } = location;
    // ReactGA.set({ page: pathname });
    // ReactGA.pageview(pathname);
  // }, [location]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // useEffect(() => {
  //   if (windowDimensions.width <= 576) {
  //     setSmallScreen(true);
  //     // setCardImg(language === 'zh' ? grantitCardMobileCn : grantitCardMobile);
  //   }
  //   if (windowDimensions.width >= 576) {
  //     setSmallScreen(false)
  //     // setCardImg(language === 'zh' ? grantitCardCn : grantitCard);
  //   }
  // }, [language, windowDimensions]);

  useEffect(() => {
    const getCarouselImages = async () => {
      await fetch(`${process.env.REACT_APP_CMS_APP_URL}/api/home-page-carousel?locale=${lang}`)
        .then(response => response.json())
        .then(data => {
          if (data && data.data) {
            setCarousel(data.data.attributes);
          } else {
            setCarousel('');
          }
      }).catch((err) => {
        console.log(err);
      });
    };

    const getVideo = async () => {
      await fetch(`${process.env.REACT_APP_CMS_APP_URL}/api/home-page-video-link?locale=${lang}`)
        .then(response => response.json())
        .then(data => {
          if (data && data.data) {
            setVideoLink(data.data.attributes.videoLink);
          } else {
            setVideoLink('');
          }
      }).catch((err) => {
        console.log(err);
      }); 
    };

    getCarouselImages();
    getVideo();
  }, [lang]);

  // const generateLink = ((mobNumber) => {
  //   return new window.AF_SMART_SCRIPT.generateOneLinkURL({
  //     oneLinkURL: "https://grantit.onelink.me/tiWR/",
  //     pidStaticValue: "landing_website",
  //     afParameters: {
  //       deepLinkValue: {
  //         keys: ["phonenumber"],
  //         defaultValue: mobNumber,
  //       }
  //     }
  //   });
  // });

  // let location = useLocation();

  // useEffect(() => {
  //   const { pathname } = location;
  //   ReactGA.set({ page: pathname });
  //   ReactGA.pageview(pathname);
  // }, [location]);
  
  // const redirectLink = (link) => {
  //   if (link === 'google') {
  //     window.open('https://play.google.com/store/apps/details?id=com.grantit&referrer=utm_source%3Dwebsite%26utm_medium%3DQR_code', '_blank');
  //   }

  // }

  const handleRedirect = (link) => {
     if(link === 'cashloan') {
        navigate('/features/cashloan');
     }
     if(link === 'smartFinancial') {
        navigate('/features/GKeeper');
     }
     if (link === 'promotion') {
        navigate('/promotions');
     }
     if (['google', 'apple'].includes(link)) {
        window.open(window.AF_SMART_SCRIPT_RESULT.clickURL, '_blank');
     }
     if (link === 'ctjobs') {
        window.open('https://www.ctgoodjobs.hk/article/%E4%BA%BA%E7%89%A9%E5%B0%88%E8%A8%AA-27%E6%AD%B2%E5[…]%8B%99%E7%99%BC%E5%B1%95fintech%E4%BA%8B%E6%A5%AD/1229-23820', '_blank');
     }
     if (link === 'hket') {
        window.open('https://topick.hket.com/article/2507035/Fintech%E6%89%93%E7%A0%B4%E5%A3%81%E5%A3%98%20%E5%88%9D%E5%89%B5%E5%80%9F%E8%B2%B8Grantit%20Apps%20%E9%9D%A0%E7%B4%B0%E5%BF%83%E8%B4%8F%E5%AE%A2%E5%BF%83', '_blank');
     }
     if (link === 'yahoo') {
        window.open('https://finance.yahoo.com/news/grantit-fintech-start-financial-services-030000755.html', '_blank');
     }
  }

  return (
    <>
    <SEO title={t('home.pageTitle')} description={t('home.metaDescription')} /> 
    <div className="home-parent">
      <Container fluid className="home" data-aos="slide-down" data-aos-duration="700">
        <Row className="h-100">
          <Col className="home-container my-auto" md={6} lg={6} sm={12} xs={12} data-aos='fade' data-aos-duration="700">
            <div className=" my-auto">
              <Row className="justify-content-center mobileContent">
                <Col className='px-3 mobileAlignment' lg={9} sm={12}>
                  <p className="homeHeading">{t('home.bannerHeading')}</p>
                  <h5 className="home-desc">{t('home.bannerDesc1')} </h5>
                  <div className="storeLinks">
                    <img src={appleImg} className="appStore" alt="appStore" onClick={() => handleRedirect('apple')}/>                
                    <img src={googleImg} className="googlePlay" alt="playStore" onClick={() => handleRedirect('google')} />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col className="homeRight my-auto" lg={6} md={6} sm={12} xs={12}>
            <img className={`HomepageBannerImage ${language === 'zh' && windowDimensions.width <= 768 ? 'bannerImageCn' : ''}`} src={language === 'zh' ? HomepageBannerImageCn : HomepageBannerImage} alt="HomepageComparison" />
          </Col>
        </Row>
      </Container>
      {/* <ThankuModal
        show={modalShow}
        smallScreen={smallScreen}
        onHide={() => setModalShow(false)}
        link={deepLink}
      /> */}
      <Container fluid className="homeCardArea p-0 overflow-hidden">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="my-auto" data-aos="fade" data-aos-duration="500">
            <Row className="justify-content-center">
               <Col md={8} sm={12}>
                <div className="text-center my-auto cardLeftMobile">
                  <p className="cardHeading">{t('home.homeCardHead')}</p>
                  <p className="cardDesc">{t('home.homeCardDesc')}</p>
                </div>
            </Col>
            </Row>
            <Row className="d-flex justify-content-center cardRowGrantit">
              <Col md={4} sm={4} xs={12} className="leftCardGrantit" onClick={() => handleRedirect("cashloan")}>     
                  <img src={cash} className="cardLogoGrantit" alt="cash"/>
                  <img src={greyArrow} alt="greyArrow" className="arrow"/> 
                  <p className="leftCardHeading">{t('home.cardHeadingGrantit')}</p>
                  <p className="leftCardPara"><ReactMarkdown  children={t('home.cardparaGrantit')}  /></p>
              </Col>
              <Col md={4} sm={4} xs={12} className="leftCardGrantit leftCardMargin" onClick={() => handleRedirect("smartFinancial")}>
                  <img src={bar} className="cardLogoGrantit" alt="bar"/> 
                  <img src={whiteArrow} alt="whiteArrow" className="arrow"/> 
                  <p className="rightCardHeading">{t('home.cardHeadingGrantit2')}</p>
                  <p className="rightCardPara"><ReactMarkdown  children={t('home.cardparaGrantit2')}  /></p>
                  {/* <div onClick={() => handleRedirect('smartFinancial')} style={language === 'en' ? { "marginTop" : "18px" } : {"marginTop" : "70px"}} className="d-flex linkMob " >
                     <p className={language === 'zh' ? "cardLinkGrantit" : "cardLinkGrantitEn"}>{t('home.cardLinkGrantit2')}<img src={rightArrow} className="mx-2" alt="rightArrow"/></p>     
                  </div> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {carousel && 
      <Container fluid className="grantitCaracol">
        <div className="d-flex justify-content-center my-auto">
          <p className="caracolHeading px-2">{t('home.carouselHeading')}</p>
        </div>
        <Row className="d-flex justify-content-center">
          <Col md={9} sm={12} xs={12}>
            <HomeSlider caresole={carousel && carousel} smallScreen={windowDimensions.width <= 768}/>
          </Col>
        </Row>
      </Container>
      }
      <Container className="home-midSection p-0 overflow-hidden" fluid>
        <p className="grantitOption" data-aos='fade' data-aos-duration="500">{t('home.advantage')}</p>
        <Row>
          <Col className={`d-flex ${windowDimensions.width < 768 ? "justify-content-center" : "justify-content-start"} mt-5`} xl={6} lg={6} md={6} sm={12} xs={12}>
            <div data-aos='fade-right' className="homeScreenStyle">
              <img className="homeScreen" alt="homeScreen" src={language === 'zh' ? homescreenCn : homescreen} />
            </div>
          </Col >
          <Col className="mt-4" lg={6} sm={12} md={6}>
            <Row>
              <Col lg={9} sm={12} md={9} className="marginMobile">
                <Feature img={barIcon} alt="barIcon" heading={t('home.AllOne')} desc={t('home.AllOneDesc')} />
                <Feature img={phoneIcon} alt="phoneIcon" heading={t('home.Anytime')} desc={t('home.AnytimeDesc')} />
                <Feature img={bulbIcon} alt="bulbIcon" heading={t('home.Fintech')} desc={t('home.FintechDesc')} />
                <Feature img={lockIcon} alt="lockIcon" heading={t('home.Security')} desc={t('home.SecurityDesc')} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center sign-up-responsive mt-3" data-aos='fade' data-aos-duration="700">
          {/* <Col lg={10} >
            <h1 className="text-center calcHeading">{t('home.calculatorHeading')}</h1>
            <Calculator />
          </Col> */}
        </Row>
        <Row >
          <Col className="customer-care">
            <h1 className="text-center">{t('home.customer.customerTitle')}</h1>
            <div className="my-5">
              < CardCarousel />
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          {videoLink && 
          <div className="finance-tool">
            <Row className=" justify-content-center" >
              <Col md={10} className="d-flex justify-content-center  ">
                <div className="finance-bgImg my-4">
                  <Row className="justify-content-center videoSection  ">
                    <Col md={8} className="my-auto">
                      <div className="screenshot">
                        <iframe
                          title="YouTube video player"
                          src={videoLink && videoLink}
                          className="grantitVideo"
                          frameBorder="0"
                          allowFullScreen
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          }
          <Row className="justify-content-center awardArea webLogos">
            <p className="awardHeading">{t('home.grantitAward')}<br /></p>
            <Row>
              <Col xl={2} />
              <Col xl={2} sm={3} className="my-auto d-flex justify-content-center">
                <img src={awardYear} alt="award" className="awardYear"/>
              </Col>
              <Col xl={4} sm={6} className="my-auto d-flex justify-content-center">
                <img src={brandAwards} alt="award" className="brandAward" />
              </Col>
              <Col xl={2} sm={3} className="my-auto d-flex justify-content-center">
                <img src={awardYear2} alt="award" className="awardYear"/>
              </Col>
              <Col xl={2} />
            </Row>
            <Row>
              <Col xl={2} lg={1} md={1} sm={1} />
              <Col xl={2} lg={1} md={1} sm={1} className="my-auto d-flex justify-content-center">
                <img src={awardYear4} alt="award" className="brandAward"/>
              </Col>
              <Col xl={4} lg={7} md={7} sm={8} className="my-auto d-flex justify-content-center">
                <img src={awardYear5} alt="award" className="brandAward" />
              </Col>
              <Col xl={2} lg={3} md={3} sm={1} className="my-auto d-flex justify-content-center">
                <img src={awardYear6} alt="award" className="brandAward"/>
              </Col>
              <Col xl={2} lg={1} md={1} sm={1} />
            </Row>
          </Row>
          
          <Row className="mobileLogos">
            <p className="awardHeading">{t('home.grantitAward')}<br /></p>
            <Row>
              <Col sm={2} xs={2} />
              <Col sm={3} xs={3} className="my-auto d-flex justify-content-center">
                <img src={awardYear} alt="award" className="awardYear"/>
              </Col>
              <Col sm={3} xs={3} />
              <Col sm={3} xs={3} className="my-auto d-flex justify-content-center">
                <img src={awardYear2} alt="award" className="awardYear" />
              </Col>
              <Col sm={1} xs={1} />
            </Row>
            <Row>
              <Col sm={1} xs={1} />
              <Col sm={4} xs={4} className="my-auto d-flex justify-content-center">
                <img src={brandAwards} alt="award" className="brandAward"/>
              </Col>
              <Col sm={4} xs={3} />
              <Col sm={2} xs={4} className="my-auto d-flex justify-content-center">
                <img src={awardYear4} alt="award" className="brandAward" />
              </Col>
              <Col sm={1} />
            </Row>
            <Row>
                <Col xs={1} />
                <Col xs={5} className="my-auto d-flex justify-content-center">
                  <img src={awardYear5} alt="award" className="awardYear" />
                </Col>
                <Col xs={1} />
                <Col xs={5} className="my-auto d-flex justify-content-center">
                  <img src={awardYear6} alt="award" className="awardYear" />
                </Col>                
                <Col xs={0} />
            </Row>
          </Row>
          <div className="press-release my-5" data-aos='fade' data-aos-duration="500">
            <h1 className="text-center">{t('home.video.press')}</h1>
            <Row className="justify-content-sm-center ">
              <LogoSlider images={imageArray} links={imageLinksArray} width={windowDimensions.width}/>
            </Row>
          </div>
          <div className="grantitAward">
          </div>
          <div className="works">
            <div className="how-works mt-4">
              <h1 className="numberText">{t('home.number.keyNumber')}</h1>
              <Row className="justify-content-sm-center py-5">
                <Col className="text-center num" lg={3} sm={12} md={4}>
                  <p className="rating text-center">{t('home.number.rating')}</p>
                  <Number start={0} end={4.8} decimal={2} value="" duration={1}  />
                </Col>
                <Col className="text-center num" lg={3} sm={12} md={4}>
                  <p className="rating text-center">{t('home.number.download')}</p>
                  <Number start={0} end={100000} decimal={0} value="" duration={1}/>
                </Col>
                <Col className="text-center num" lg={3} sm={12} md={4}>
                  <p className="rating text-center ">{t('home.number.amount')}</p>
                  <Number prefix="$" start={0} end={994} decimal={0} value="M" duration={1}/>
                </Col>
              </Row>
              </div>
              <div className="signupForm">
                <GetStarted  desc={t('signUp.signDesc')}/>
              </div>
            </div>
        </Row>
      </Container>
    </div>
    </>  
  )
}

export default Home