import React from 'react';
import clss from 'classnames';
import styles from './styles.module.scss';

// type: horizontal, vertical
// size: number * base-size
function Spacer({
  type = 'vertical',
  size = 1,
  xlSize = undefined,
  lgSize = undefined,
}) {
  return (
    <div
      className={clss(styles.spacer, styles[`${type}-${xlSize ?? size}`], {
        [styles[`${type}-lg-${lgSize}`]]: lgSize !== undefined,
      })}
    />
  );
}

export default Spacer;
