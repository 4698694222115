import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './footer.css';
import facebook from '../../../components/footer/images/facebook.svg';
import youtube from '../../../components/footer/images/youtube.svg';
import instagram from '../../../components/footer/images/instagram.svg';
import linkedin from '../../../components/footer/images/linkedin.svg';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();


    const redirectLink = (link) => {
        if (link === 'fb') window.open('https://www.facebook.com/grantithk', '_blank');
        if (link === 'youtube') window.open('https://www.youtube.com/channel/UCzZBFqFJt62RrZScTWQ0BTg', '_blank');
        if (link === 'insta') window.open('https://www.instagram.com/grantit.io', '_blank');
        if (link === 'linkedin') window.open('https://www.linkedin.com/company/grantit-limited/?viewAsMember=true', '_blank');
    }

    return (
        <Container fluid className="p-0 overflow-hidden">
            <div className="landingFooter">
                <Row>
                    <Col sm={12} md={6}>
                        <div className="onesubFooter">
                            <div className="landingLogoDiv">
                                <p className="landingLogo" style={{marginLeft:'0'}}>{t('landing.logo')}</p>
                                <p className="logoDesc" style={{marginLeft:'0'}}>{t('landing.logoDesc')}</p>
                            </div>
                            <p className="landingAddress">{t('footer.address')}<br />(852) 2577 7117</p>
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <div className="landingLinks">
                            <p className="landingPolicy">Privacy Policy</p>
                            <div className="social-links px-3 mt-0">
                                <ul className="linkList">
                                    <li><div onClick={() => redirectLink('fb')} className="link"><img className="social-icon" src={facebook} alt="" /></div></li>
                                    <li><div onClick={() => redirectLink('youtube')} className="link"><img className="social-icon" src={youtube} alt="" /></div></li>
                                    <li><div onClick={() => redirectLink('insta')} className="link"><img className="social-icon" src={instagram} alt="" /></div></li>
                                    <li><div onClick={() => redirectLink('linkedin')} className="link"><img className="social-icon" src={linkedin} alt="" /></div></li>
                                </ul>
                            </div>
                        </div>

                    </Col>

                </Row>
            </div>
        </Container>
    );
}

export default Footer;
