import { createContext, useState } from "react";

export const LanguageContext = createContext();

export const LanguageContextProvider = ({ children }) => {
    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng'));

    const changeLanguage = (lang) => {
        setLanguage(lang);
    }


    return (
        <LanguageContext.Provider
            value={{
                language,
                changeLanguage
            }}
        >
            {children}
        </LanguageContext.Provider>
    );
};