import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import './signup.css';
import { useTranslation } from 'react-i18next';
import ThankuModal from '../thankumodal/ThankuModal';
import AOS from 'aos';
import ReactMarkdown from 'react-markdown';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function SignUp({ desc, isGKeeper }) {
  const { t } = useTranslation();
  const [number, setNumber] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [smallScreen, setSmallScreen] = useState(false);
  const [error, setError] = useState('');
  AOS.init({
    duration : 1000
  })

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [deepLink, setLink] = useState('');

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowDimensions.width <= 576) {
      setSmallScreen(true)
    }
    if (windowDimensions.width >= 576) {
      setSmallScreen(false)
    }
  }, [windowDimensions])

  useEffect(() => {
    if (number.length === 0) {
      setError('');
    }

  }, [number])

  const generateLink = ((mobNumber) => {
    return new window.AF_SMART_SCRIPT.generateOneLinkURL({
      oneLinkURL: "https://grantit.onelink.me/tiWR/",
      pidStaticValue: "landing_website",
      afParameters: {
        deepLinkValue: {
          keys: ["phonenumber"],
          defaultValue: mobNumber,
        }
      }
    });
  });

  const handleInput = (e) => {
   
    const re = /^[0-9]+$/; 
    if (e.target.value === "" || re.test(e.target.value)) {
      setNumber(e.target.value);
    }
    
  }

  const handleSubmit = async () => {
    if (number.length === 8) {

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify({ data: { mobileno: number } })
      };
      
      
      await fetch(`${process.env.REACT_APP_CMS_APP_URL}/api/signups`, requestOptions)
        .catch(err => console.error(err))

      setLink(generateLink(number));

      setModalShow(true);
      setError('');
      setNumber('')

    } else {
      setError(t('signUp.errorMsg'))
    }
  }
  return (
    <>
      <div className="signUp">
        <h4 className="signUpheader">{t('signUp.sign')}</h4>
        <ReactMarkdown className={`signUp-text ${isGKeeper ? 'gKeeper-text': ''}`} children={desc} />
        <Row>

          <Col sm={7} className="d-flex my-auto inputArea">
            <p className="countrycode my-auto">+852</p>
            <input value={number} maxLength="8" placeholder={t('signUp.mobile')} className="numberInput" onChange={e => handleInput(e)} />
          </Col>
          <Col sm={4} onClick={handleSubmit} className="submit">{t('signUp.submit')}</Col>
        </Row>
        <p className="submitError ">{error}</p>
      </div>


      <ThankuModal
        show={modalShow}
        smallScreen={smallScreen}
        onHide={() => setModalShow(false)}
        link={deepLink}
      />
    </>
  )
}

export default SignUp
