import React from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'

function Spiner() {
    return (
        <Container  >
            <Row style={{'marginTop':"280px"}}>
                <Col  className="d-flex justify-content-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Col>
            </Row>
        </Container>
    )
}

export default Spiner
