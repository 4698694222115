// The following transform is needed for Yup's number()
// because it cannot properly parse the X,XXX,XXX.XX format into number
export const thousandSeparatorReverse = (_, originalValue) => {
  if (typeof originalValue === 'number') {
    return originalValue;
  }
  if (originalValue == null) {
    return undefined;
  }
  if (typeof originalValue === 'string' && originalValue === '') {
    return undefined;
  }
  return Number(originalValue.replace(/,/g, ''));
};

export const spaceRemover = (_, originalValue) => {
  return originalValue.replace(/ /g, '');
};
