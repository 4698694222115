import { useMemo, useState } from 'react';
import Collapsible from 'react-collapsible';
import useFormPersist from 'react-hook-form-persist';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';

import Button from 'components/atoms/button';
import Typography from 'components/atoms/typography';
import StepLayout from 'components/layout/step';
import TagRadio from 'components/form/tagradio';
import Stack from 'components/atoms/stack';
import NumberInput from 'components/form/numberinput';
import Modal from 'components/atoms/modal';

import hkidSymbolsEN from './images/hkid-symbols-en.webp';
import hkidSymbolsZH from './images/hkid-symbols-zh.webp';
import { EligibilitySchema, getFormFields } from './const';
import styles from './styles.module.scss';

function EligibilityCheck() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [showhSymbolModal, setSymbolModalState] = useState(false);

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EligibilitySchema),
    defaultValues: {},
  });

  useFormPersist('eligibility', {
    watch,
    setValue,
    storage: window.localStorage,
  });

  const [housingType, currentPositionYears, accomodationType] = watch([
    'housingType',
    'currentPositionYears',
    'accomodationType',
  ]);

  // i18n deep calls can't be recognised, let's mask the warning first.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const FormFields = useMemo(() => getFormFields(), [i18n.language]);

  const onSubmit = () => {
    navigate('/features/cashloan/sign-up');
  };
  return (
    <>
      <StepLayout activeIndex={0}>
        <Typography
          xlSize="xh1"
          lgSize="h1"
          weight="semibold"
          color="title"
          wrap
        >
          {t('eligibility.title.form')}
        </Typography>
        <Controller
          name="age"
          control={control}
          render={({ field }) => (
            <TagRadio
              {...field}
              title={t('eligibility.title.yourAge')}
              sequence={'1/7'}
              options={FormFields.age}
              error={errors?.age && t('common.validations.requiredSelection')}
            />
          )}
        />
        <Controller
          name="hkidSymbol"
          control={control}
          render={({ field }) => (
            <TagRadio
              {...field}
              onTip={() => setSymbolModalState(true)}
              tip={t('eligibility.title.howToFindSymbols')}
              title={t('eligibility.title.hkidSymbol')}
              sequence={'2/7'}
              options={FormFields.hkidSymbol}
              error={
                errors?.hkidSymbol && t('common.validations.requiredSelection')
              }
            />
          )}
        />
        <Controller
          name="maritalStatus"
          control={control}
          render={({ field }) => (
            <TagRadio
              {...field}
              title={t('eligibility.title.maritalStatus')}
              sequence={'3/7'}
              options={FormFields.maritalStatus}
              error={
                errors?.maritalStatus &&
                t('common.validations.requiredSelection')
              }
            />
          )}
        />
        <Controller
          name="creditCardsNum"
          control={control}
          render={({ field }) => (
            <TagRadio
              {...field}
              title={t('eligibility.title.creditCardsNum')}
              options={FormFields.creditCardsNum}
              sequence={'4/7'}
              error={
                errors?.creditCardsNum &&
                t('common.validations.requiredSelection')
              }
            />
          )}
        />
        <Controller
          name="averageInstallmentPerMonth"
          control={control}
          render={({ field }) => (
            <NumberInput
              {...field}
              title={t('eligibility.title.aveInstallmentPerMonth')}
              placeholder={t('eligibility.title.inputAveInstallment')}
              sequence={'5/7'}
              error={
                errors?.averageInstallmentPerMonth &&
                t('common.validations.requiredRepayment')
              }
            />
          )}
        />
        <Stack direction="column" spacing={!!housingType ? 5 : 0}>
          <Controller
            name="housingType"
            control={control}
            render={({ field }) => (
              <TagRadio
                {...field}
                onChange={(data) => {
                  clearErrors('housingType');
                  clearErrors('accomodationType');
                  clearErrors('monthlyRent');
                  field.onChange(data);
                }}
                title={t('eligibility.title.housingProperty')}
                options={FormFields.housingType}
                sequence={'6/7'}
                error={
                  errors?.housingType &&
                  t('common.validations.requiredSelection')
                }
              />
            )}
          />
          <Controller
            name="accomodationType"
            control={control}
            render={({ field }) => (
              <Collapsible
                className={styles.collapsible}
                openedClassName={styles.collapsible}
                open={!!housingType}
              >
                <Stack direction="column" spacing={2} fullWidth>
                  <Typography>
                    {t('eligibility.title.accomodationType')}
                  </Typography>
                  <TagRadio
                    {...field}
                    gappable={false}
                    options={FormFields.accomodationType}
                    error={
                      errors?.accomodationType &&
                      t('common.validations.requiredSelection')
                    }
                  />
                </Stack>
              </Collapsible>
            )}
          />
          <Controller
            name="monthlyRent"
            control={control}
            render={({ field }) => (
              <Collapsible
                className={styles.collapsible}
                openedClassName={styles.collapsible}
                open={!!housingType && accomodationType === 'rental'}
              >
                <Stack direction="column" spacing={4} fullWidth>
                  <Typography>
                    {t('eligibility.title.monthlyRentalAmount')}
                  </Typography>
                  <TagRadio
                    {...field}
                    gappable={false}
                    options={FormFields.monthlyRent}
                    error={
                      errors?.monthlyRent &&
                      t('common.validations.requiredSelection')
                    }
                  />
                </Stack>
              </Collapsible>
            )}
          />
        </Stack>
        <Stack
          direction="column"
          spacing={
            currentPositionYears && currentPositionYears !== 'unemployed'
              ? 5
              : 0
          }
        >
          <Controller
            name="currentPositionYears"
            control={control}
            render={({ field }) => (
              <TagRadio
                {...field}
                onChange={(data) => {
                  clearErrors('currentPositionYears');
                  clearErrors('monthlySalary');
                  clearErrors('incomePaymentMethod');
                  field.onChange(data);
                }}
                title={t('eligibility.title.workForHowLong')}
                options={FormFields.currentPositionYears}
                sequence={'7/7'}
                error={
                  errors?.currentPositionYears &&
                  t('common.validations.requiredSelection')
                }
              />
            )}
          />
          <Controller
            name="monthlySalary"
            control={control}
            render={({ field }) => (
              <Collapsible
                className={styles.collapsible}
                openedClassName={styles.collapsible}
                open={
                  currentPositionYears && currentPositionYears !== 'unemployed'
                }
              >
                <Stack direction="column" spacing={2} fullWidth>
                  <Typography>{t('eligibility.title.montlySalary')}</Typography>
                  <NumberInput
                    {...field}
                    prefix="$"
                    gappable={false}
                    placeholder={t('eligibility.title.inputSalary')}
                    error={
                      errors?.monthlySalary &&
                      t('common.validations.requiredSalary')
                    }
                  />
                </Stack>
              </Collapsible>
            )}
          />
          <Controller
            name="incomePaymentMethod"
            control={control}
            render={({ field }) => (
              <Collapsible
                className={styles.collapsible}
                openedClassName={styles.collapsible}
                open={
                  currentPositionYears && currentPositionYears !== 'unemployed'
                }
              >
                <Stack direction="column" spacing={2} fullWidth>
                  <Typography wrap>
                    {t('eligibility.title.incomePaymentMethod')}
                  </Typography>
                  <TagRadio
                    {...field}
                    gappable={false}
                    options={FormFields.incomePaymentMethods}
                    error={
                      errors?.incomePaymentMethod &&
                      t('common.validations.requiredSelection')
                    }
                  />
                </Stack>
              </Collapsible>
            )}
          />
        </Stack>
        <Button.Primary fullSize="lg" onClick={handleSubmit(onSubmit)}>
          <Typography size="h4" weight="medium" color="white">
            {t('eligibility.submit')}
          </Typography>
        </Button.Primary>
      </StepLayout>
      <Modal
        show={showhSymbolModal}
        onHide={() => setSymbolModalState(false)}
      >
        <Stack
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography size="h1" weight="medium" color="title">
            {t('eligibility.hkidModal.title')}
          </Typography>
          <Typography size="title1" weight="normal" color="body">
            {t('eligibility.hkidModal.description')}
          </Typography>
          <img
            alt="hkid-symbol"
            className={styles.hkidSymbolsImg}
            src={i18n.language === 'zh' ? hkidSymbolsZH : hkidSymbolsEN}
          />
          <Button.Primary onClick={() => setSymbolModalState(false)}>
            <Typography size="h4" weight="medium" color="white">
              {t('common.close')}
            </Typography>
          </Button.Primary>
        </Stack>
      </Modal>
    </>
  );
}

export default EligibilityCheck;
