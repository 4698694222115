import React from 'react';
import './blog.css';
import { Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

function Blog({ blog }) {
    const { attributes: { category, title, metaDescription, thumbnailImgLink, slug, imgCaption } } = blog;
    const navigate = useNavigate();

    const blogDetail = () => {
        navigate(`/blog_details?slug=${slug}`, { state: blog });
    }

    return (
        <>
            <Col lg={3} md={6} sm={12} className="p-2 blogClick">
                <div className="imageArea" onClick={blogDetail}>
                    <img src={`${thumbnailImgLink}`} className="blogimg" alt={imgCaption} />
                </div>
                <div className="blog-content">
                    <p className="blogCategory">{category}</p>
                    <h6 className="blogTitle" onClick={blogDetail}>{title}</h6>
                    <p className="blogMetaDesc" onClick={blogDetail}><ReactMarkdown children={`${metaDescription.substring(0, 150)}...`} /></p>
                </div>
            </Col>
        </>
    )
}

export default Blog
