export const generateDeepLinkWithPhoneNumber = (phoneNumber = '') => {
  //Initializing Smart Script arguments
  const oneLinkURL = 'https://grantit.onelink.me/tiWR';
  const mediaSource = { keys: ['utm_source'], defaultValue: 'website' };
  const campaign = { keys: ['utm_campaign'], defaultValue: 'website' };
  const channel = { keys: ['utm_medium'], defaultValue: 'website' };
  const custom_ss_ui = { paramKey: 'af_ss_ui', defaultValue: 'true' };
  const custom_ss_gtm_ui = { paramKey: 'af_ss_gtm_ui', defaultValue: 'true' };

  //Function is embedded on the window object in a global parameter called window.AF_SMART_SCRIPT.
  //Onelink URL is generated.
  //result: {clickURL: string}
  const result = window.AF_SMART_SCRIPT.generateOneLinkURL({
    oneLinkURL: oneLinkURL,
    afParameters: {
      mediaSource: mediaSource,
      campaign: campaign,
      channel: channel,
      afCustom: [custom_ss_ui, custom_ss_gtm_ui],
      deepLinkValue: {
        keys: ['phonenumber'],
        defaultValue: `tel:${phoneNumber}`,
      },
    },
  });

  //result add displayQrCode
  //Because this function is only valid after generateOneLinkURL
  //displayQrCode need element-id
  //doc: https://dev.appsflyer.com/hc/docs/dl_smart_script_v2#displayqrcode
  result.displayQrCode = window.AF_SMART_SCRIPT.displayQrCode;

  return result;
};
