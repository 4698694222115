import React, { useState } from 'react';
import './opportunity.css';
import { Col, Row } from 'react-bootstrap';
import rightarrow from '../../pages/joinus/images/rightarrow.svg';
import downarrow from '../../pages/joinus/images/downarrow.svg';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

function Opportunity({ opportunity }) {
    const { t } = useTranslation();
    const { attributes: { heading, description, jobLink } } = opportunity;
    const [show, setShow] = useState(false);
    const handleShow = () => {
        setShow(!show);
    }

    const jobLinkRedirect = () => {
        window.open(jobLink);
    }

    return (
        <>
            <Col md={9} sm={12} className="opportunitesToogle">
                <Row>
                    <Col lg={9} sm={12}>
                        <div className="d-flex ">
                            <p className="joinus-toggled cursor" onClick={handleShow}>{heading}</p>
                            <p className="mx-2"><img src={show ? downarrow : rightarrow} alt="rightarrow" className="rightarrow" onClick={handleShow} /></p>
                        </div>
                        {show &&
                            <div className="opportunityDesc" data-aos="fade" data-aos-delay="50" data-aos-duration="1000">
                                <ReactMarkdown children={t(description)} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}/>
                                <Col sm={4} xs={8} >
                                    <div className="linkedin ">
                                        <h4 onClick={jobLinkRedirect} className="linkedin-btn text-center">Apply now</h4>
                                    </div>
                                </Col>
                            </div>
                        }
                    </Col>
                </Row>
            </Col>
        </>
    )
}

export default Opportunity
