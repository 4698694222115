const formatTime = (time) => `0${time}`.slice(-2);

export const getRemainingTime = ({ time, format = 'mm:ss' }) => {
  let hour;
  let min;
  let sec;
  switch (format) {
    case 'hh:mm:ss':
      hour = Math.floor(time / 60 / 60);
      min = Math.floor(time / 60) - hour * 60;
      sec = time - min * 60 - hour * 60 * 60;
      return {
        time: `${formatTime(hour)}:${formatTime(min)}:${formatTime(sec)}`,
      };
    case 'mm:ss':
    default:
      min = Math.floor(time / 60);
      sec = time - min * 60;
      return { time: `${formatTime(min)}:${formatTime(sec)}` };
  }
};
