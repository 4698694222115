import Primary from './Primary';
import Ghost from './Ghost';

// Resolving Warning: import/no-anonymous-default-export
const Button = {
  Primary,
  Ghost
  // add other type
  // ...
  // ...
};

export default Button;
