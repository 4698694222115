import React from 'react';
import './offer.css';
import { Container, Row, Col } from 'react-bootstrap';
import alert from './images/alert.svg';
import remove from './images/remove.svg';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

function Offer({ announcement, isSmall, annoucementClose }) {
  const expand = announcement && announcement.length > 25;
  const heightExpand = isSmall ? 99 : 79;
  const heightNormal = isSmall ? 76 : 56;

  const rowMarginTop = isSmall ? 8 : 0;
  const rowPaddingTop = !expand ? 5 : 0;
  const rowStyle = {
    marginTop: `${rowMarginTop}px`,
    paddingTop: `${rowPaddingTop}px`
  };

  return (
    <Container style={expand ? { 'height': `${heightExpand}px` } : { 'maxHeight': `${heightNormal}px`, 'height': 'auto' }} fluid className="p-0 overflow-hidden offerArea"
      >
      <Row style={rowStyle} className={!isSmall ? "px-5 h-100" : "px-1"}>
        <Col sm={11} xs={11} className="d-flex">
          <img src={alert} className={`alertImg ${!isSmall && 'my-auto'}`} alt="alert" />
          <div className={!isSmall && "annoucementStyle my-auto"} >
          <ReactMarkdown className="alertDesc my-auto" children={announcement} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}/>
          </div>
        </Col>
        <Col sm={1} xs={1} className="d-flex">
          <img src={remove}
            alt="close"
            className={`closeButton ${!isSmall && 'my-auto'}`}
            onClick={annoucementClose}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default Offer