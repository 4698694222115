import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import arrowUp from '../../components/gkeeperfaq/images/arrowSecondUp.svg';
import arrowDown from '../../components/gkeeperfaq/images/arrowSecondDown.svg';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';


function Account() {
    const [show, setShow] = useState('');
    const { t } = useTranslation();

    const handleShow = (action) => {
        if (action === 'update' || 'security' || 'openaccount' ) {
            setShow((prev) => prev === action ? '' : action);
        }
    }

    return (
        <>
            <Col style={{ 'marginTop': '38px' }}>
                <div className="faqDivider"></div>
                <div className="help-container " >
                <div  className="d-flex justify-content-between" onClick={() => handleShow('update')}>
                        <h4 style={show === 'update' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.accountTab.update')}</h4>
                        <img  src={show === 'update' ? arrowDown: arrowUp} alt="plus" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'update' &&
                    <div>
                        <p className="faq-desc"><ReactMarkdown children={t('faq.accountTab.updateDesc')} /></p>
                    </div>
                }
            </Col>
            <Col>
                <div className="faqDivider"></div>
                <div className="help-container ">
                <div  className="d-flex justify-content-between" onClick={() => handleShow('security')}>
                        <h4 style={show === 'security' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.accountTab.security')}</h4>
                        <img  src={show === 'security' ? arrowDown: arrowUp} alt="plus" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'security' &&
                    <div>
                        <p className="faq-desc"><ReactMarkdown children={t('faq.accountTab.securityDesc')} /></p>
                    </div>
                }
            </Col>
            <Col>
                <div className="faqDivider"></div>
                <div className="help-container ">
                <div  className="d-flex justify-content-between" onClick={() => handleShow('openaccount')}>
                        <h4 style={show === 'openaccount' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.accountTab.openaccount')}</h4>
                        <img  src={show === 'openaccount' ? arrowDown: arrowUp} alt="plus" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'openaccount' &&
                    <div>
                        <p className="faq-desc"><ReactMarkdown children={t('faq.accountTab.openaccountDesc')} /></p>
                    </div>
                }
            </Col>
            <div className="faqDivider"></div>         
        </>
    )
}

export default Account
