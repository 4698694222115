import React, { useState, useEffect, useContext } from 'react';
import "./calculator.css";
import calculator from './images/calculator.svg';
import { Col } from 'react-bootstrap';
import Step1 from './step1/Step1';
import Step2 from './step2/Step2';
import Step3 from './step3/Step3';
import Step4 from './step4/Step4';
import { useTranslation } from 'react-i18next';
import Spiner from '../../components/spinner/Spiner';
import {LanguageContext} from '../../context/LanguageContext';

function Calculator() {
    const [step, setStep] = useState(1);
    const [step1Clear, setStep1Clear] = useState(false);
    const [step2Clear, setStep2Clear] = useState(false);
    const [step3Clear, setStep3Clear] = useState(false);
    const [recordId, setRecordId] = useState('');
    const [loading, setLoading] = useState(false);
    const [goal, setGoal] = useState([]);
    const [formData, setFormData] = useState({
        age: "1",
        monthlyIncome: null,
        monthlyExpense: null,
        totalSavings: null,
        propertyAmount: null,
        propertyTimeline: null,
        carAmount: null,
        carTimeline: null,
        marriageAmount: null,
        marriageTimeline: null,
        travelAmount: null,
        travelTimeline: null,
        retirementAmount: null,
        retirementTimeline: null,
        mobileno: null
    });
    const [submitError, setSubmitError] = useState('');

    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);

    useEffect(() => {
        const validate = () => {
                (formData.age && formData.age !== '1') && formData.monthlyIncome && formData.monthlyExpense && formData.totalSavings ? setStep1Clear(true) : setStep1Clear(false);
            (formData.propertyAmount && formData.propertyTimeline) || (formData.carAmount && formData.carTimeline) || (formData.marriageAmount && formData.marriageTimeline) 
            || (formData.travelAmount && formData.travelTimeline) || (formData.retirementAmount && formData.retirementTimeline) 
            ? setStep2Clear(true) : setStep2Clear(false);
            (formData.mobileno && formData.mobileno.length === 8) ? setStep3Clear(true) : setStep3Clear(false);
        };

        validate();
    }, [formData]);

    const submitRequest = async () => {
        setLoading(true);
        setSubmitError('');
        await fetch(`${process.env.REACT_APP_CMS_APP_URL}/api/userinfos`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            referrerPolicy: 'no-referrer', 
            body: JSON.stringify({ data: { locale: language, formData } }) 
          }).then(response => response.json())
          .then(data => {
            setLoading(false);
            if (data.error) {
                setSubmitError(data.error);
            } else {
                setGoal(data);
                setRecordId(data.recordId); 
                nextStep();
            }    
          }).catch((err) => {
              setSubmitError(err);
              console.log(err);
          });
    }

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const handleInputData = (input, val) => e => {
        let value = '';
        if (input.toLowerCase().includes('timeline')) {
            value = new Date(e).toLocaleDateString('en-CA');
        } else {
            value = val || e.target.value;
        }
        value = value.split(',').join("")
        setFormData(prevState => ({
            ...prevState,
            [input]: value
        }));
    }

    switch (step) {
        case 1:
            return (
                <div className="calculator">
                <Col lg={12}>
                    <div className="calculatorstep">
                        <Step2 handleNext={nextStep} handleFormData={handleInputData} values={formData} step2Clear={step2Clear} />
                    </div>
                </Col>
            </div>
            );

        case 2:
            return (
                <div className="calculator">
                <Col lg={12}>
                    <div className="d-flex">
                        <div>
                            <img src={calculator} alt="calculator" />
                        </div>
                        <div>
                            <h4 className="calculatorHeading">{t('home.calculator.title')}</h4>
                        </div>
                    </div>
                    <div className="calculatorDesc">
                        <p className="budget">{t('home.calculator.step1Subtitle')}</p>
                    </div>
                    <div className="calculatorstep">
                        <Step1 nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData} step1Clear={step1Clear} />
                    </div>
                </Col>
            </div>
            );

        case 3:
            return (
                <div className="calculator">
                    <Col lg={12}>
                        <div className="d-flex">
                            <div>
                                <img src={calculator} alt="calculator" />
                            </div>
                            <div>
                                <h4 className="calculatorHeading">{t('home.calculator.title')}</h4>
                            </div>
                        </div>
                        <div className="calculatorDesc">
                            <p className="budget">{t('home.calculator.step3Subtitle')}</p>
                        </div>
                        <div className="calculatorstep">
                            <Step3 prevStep={prevStep} submitData={submitRequest} 
                                handleFormData={handleInputData} values={formData} 
                                setFormData={setFormData} step3Clear={step3Clear} error={submitError} />
                        </div>
                    </Col>
                </div>
            );
        case 4:
            return (
                <div className="calculator">
                    {
                        loading ? <Spiner /> : 
                        <Col lg={12}>
                        <div className="d-flex">
                            <div>
                                <img src={calculator} alt="calculator" />
                            </div>
                            <div>
                                <h4 className="calculatorHeading">{t('home.calculator.title')}</h4>
                            </div>
                        </div>
                        <div className="calculatorstep">
                            <Step4 goal={goal} recordId={recordId} />
                        </div>
                    </Col>
                    }
                   
                </div>
            );

        default:
            return (
                <div>
                </div>
            );
    }
}

export default Calculator

