import React from 'react';
import clss from 'classnames';
import Typography from 'components/atoms/typography';

import styles from './styles.module.scss';

// children: string
// active: bool
function Tag({ onClick, children, active }) {
  return (
    <div
      className={clss(styles.tag, { [styles.active]: active })}
      onClick={onClick}
    >
      <Typography
        xlSize="h4"
        lgSize="title2"
        weight="medium"
        color={active ? 'white' : 'inactive'}
      >
        {children}
      </Typography>
    </div>
  );
}

export default Tag;
