import React from 'react';
// import { useLocation } from 'react-router-dom';
// import ReactGA from 'react-ga';
import './ordinence.css';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';

function Ordinance() {
    const { t } = useTranslation();

    // let location = useLocation();

    // useEffect(() => {
    //   const { pathname } = location;
    //   ReactGA.set({ page: pathname });
    //   ReactGA.pageview(pathname);
    // }, [location]);

    return (
       <>
       <Container>
           <Row className="justify-content-center">
               <Col className="term " md={10} sm={12}>
                   <h2 className="head">{t('mlo.title')}</h2>
                   <ReactMarkdown className="termContent" children={t('mlo.content')}  />
               </Col>       
           </Row>
       </Container>
       </>
    )
}

export default Ordinance
