import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import { BrowserRouter as Router } from 'react-router-dom';
import AOS from 'aos';
// import { useTranslation } from 'react-i18next';
import Main from './components/main/Main';

function App() {
  AOS.init({
    once: true,
    delay: 1000,
    duration: 1500,
  });

  // const { t } = useTranslation();

  return (
    <HelmetProvider>
      <div className="App">
        <Router>
          <ScrollToTop />
          <Main />
        </Router>
      </div>
    </HelmetProvider>
  );
}
export default App;
