import React from 'react';
import './slider.css';
import Slider from "react-slick";
import { Container, Col, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

function HomeSlider({ caresole, smallScreen }) {

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const navigate = useNavigate();

  // const handleRedirect = (link) => {
  //   if (link === 'promotion') {
  //     navigate('/promotions');
  //  }
  // };

  return (
    <div className="caresoleSection">
      <Slider {...settings}>
        <Container fluid className="carouselStyle" >
          <Row className="h-100">
            <Col lg={3} sm={12} xs={12} className="carouselText">
              <p className="carouselTitle">{caresole.title1}</p>
              <p className="carouselDesc">{caresole.description1}</p>
            </Col>
            <Col lg={9} sm={12} xs={12} className="caresoleImg">
              <img
                alt="carousel1"
                src={smallScreen ? `${caresole.imageMobile1Link}` : `${caresole.imageDesktop1Link}`}
              />
            </Col>
            <Col xs={12} className="mobileCarouselText">
              <p className="carouselTitle">{caresole.title1}</p>
              <p className="carouselDesc">{caresole.description1}</p>
            </Col>
          </Row>
        </Container>
        <Container fluid className="carouselStyle" >
          <Row className="h-100">
            <Col lg={3} className="carouselText">
              <p className="carouselTitle">{caresole.title2}</p>
              <p className="carouselDesc">{caresole.description2}</p>
            </Col>
            <Col lg={9} sm={12} xs={12} className="caresoleImg">
              <img
                alt="carousel2"
                src={smallScreen ? `${caresole.imageMobile2Link}` : `${caresole.imageDesktop2Link}`}
              />
            </Col>
            <Col sm={12} xs={12} className="mobileCarouselText">
              <p className="carouselTitle">{caresole.title1}</p>
              <p className="carouselDesc">{caresole.description1}</p>
            </Col>
          </Row>
        </Container>
        <Container fluid className="carouselStyle" >
          <Row className="h-100">
            <Col lg={3} className="carouselText">
              <p className="carouselTitle">{caresole.title3}</p>
              <p className="carouselDesc">{caresole.description3}</p>
            </Col>
            <Col lg={9} sm={12} xs={12} className="caresoleImg">
              <img
                alt="carousel3"
                src={smallScreen ? `${caresole.imageMobile3Link}` : `${caresole.imageDesktop3Link}`}
              />
            </Col>
            <Col sm={12} xs={12} className="mobileCarouselText">
              <p className="carouselTitle">{caresole.title1}</p>
              <p className="carouselDesc">{caresole.description1}</p>
            </Col>
          </Row>
        </Container>
        <Container fluid className="carouselStyle" >
          <Row className="h-100">
            <Col lg={3} className="carouselText">
              <p className="carouselTitle">{caresole.title4}</p>
              <p className="carouselDesc">{caresole.description4}</p>
            </Col>
            <Col lg={9} sm={12} xs={12} className="caresoleImg">
              <img
                alt="carousel4"
                src={smallScreen ? `${caresole.imageMobile4Link}` : `${caresole.imageDesktop4Link}`}
              />
            </Col>
            <Col sm={12} xs={12} className="mobileCarouselText">
              <p className="carouselTitle">{caresole.title1}</p>
              <p className="carouselDesc">{caresole.description1}</p>
            </Col>
          </Row>
        </Container>
      </Slider>
    </div>
  )
}

export default HomeSlider