import React, { useState, useContext }  from 'react';
import './faq.css';
import {Container, Row, Col, Tabs, Tab} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../context/LanguageContext';
// import minus from './images/minus.svg';
// import plus from './images/plus.svg';
// import ReactMarkdown from 'react-markdown';
import up from './images/arrowSecondUp.svg';
import down from './images/arrowSecondDown.svg';

function Faq() {
    const [show, setShow] = useState('');
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);

    const handleShow = (action) => {
        if (action === '1stQ' || '2stQ' || '3stQ' || '4stQ' || '5stQ' || '6stQ' || '7stQ' || '8stQ' || '9stQ' || '10stQ'
            || '11stQ' || '12stQ' || '13stQ' || '14stQ') {
            setShow((prev) => prev === action ? '' : action);
        }
    }

  return (
    <Container className="faqArea">
    <p className="faqTextHeading">{language === 'zh' ? '常見問題' : 'Frequently asked questions'}</p>
    <Row className="justify-content-center">
        <Col md={9} sm={12} className="faqContent">
            <Tabs
                defaultActiveKey="About G-Keeper"
                transition={false}
                id="About G-Keeper"
                className="mb-3"
                fill
            >
                <Tab eventKey={"About G-Keeper"} title={t('gkeeper.heading1')}>
                    <Col style={{ 'marginTop': '38px' }}>
                        <div className="faqDivider"></div>
                        <div className="help-container ">
                            <div className="d-flex justify-content-between" onClick={() => handleShow('1stQ')}>
                                <h4 style={show === '1stQ' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC" >{t('gkeeper.faqQues1')}</h4>
                                <img src={show === '1stQ' ? down : up} alt="plus" className="cursor mx-2"/>
                            </div>
                        </div>
                        {show === '1stQ' && 
                        <p className="faq-desc">{t('gkeeper.faqAns1')}</p>
                        }
                        <div className="faqDivider"></div>
                        <div className="help-container ">
                            <div className="d-flex justify-content-between" onClick={() => handleShow('2stQ')}>
                                <h4 style={show === '2stQ' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC" >{t('gkeeper.faqQues2')}</h4>
                                <img src={show === '2stQ' ? down : up} alt="plus" className="cursor mx-2"/>
                            </div>
                        </div>
                        {show === '2stQ' && 
                        <p className="faq-desc">{t('gkeeper.faqAns2')}</p>
                        }
                        <div className="faqDivider"></div>
                        <div className="help-container ">
                            <div className="d-flex justify-content-between" onClick={() => handleShow('3stQ')}>
                                <h4 style={show === '3stQ' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC" >{t('gkeeper.faqQues3')}</h4>
                                <img src={show === '3stQ' ? down : up} alt="plus" className="cursor mx-2"/>
                            </div>
                        </div>
                        {show === '3stQ' && 
                        <p className="faq-desc">{t('gkeeper.faqAns3')}</p>
                        }
                        <div className="faqDivider"></div>
                        <div className="help-container ">
                            <div className="d-flex justify-content-between" onClick={() => handleShow('4stQ')}>
                                <h4 style={show === '4stQ' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC" >{t('gkeeper.faqQues4')}</h4>
                                <img src={show === '4stQ' ? down : up} alt="plus" className="cursor mx-2"/>
                            </div>
                        </div>
                        {show === '4stQ' && 
                        <p className="faq-desc">{t('gkeeper.faqAns4')}</p>
                        }
                        <div className="faqDivider"></div>
                    </Col>
                </Tab>
                <Tab eventKey={"New 6-digit PIN"} title={t('gkeeper.heading2')}>
                    <Col style={{ 'marginTop': '38px' }}>
                        <div className="faqDivider"></div>
                        <div className="help-container ">
                            <div className="d-flex justify-content-between" onClick={() => handleShow('5stQ')}>
                                <h4 style={show === '5stQ' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC" >{t('gkeeper.faqQues5')}</h4>
                                <img src={show === '5stQ' ? down : up} alt="plus" className="cursor mx-2"/>
                            </div>
                        </div>
                        {show === '5stQ' && 
                        <p className="faq-desc">{t('gkeeper.faqAns5')}</p>
                        }
                        <div className="faqDivider"></div>
                        <div className="help-container">
                            <div className="d-flex justify-content-between" onClick={() => handleShow('6stQ')}>
                            <h4 style={show === '6stQ' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC" >{t('gkeeper.faqQues6')}</h4>
                            <img src={show === '6stQ' ? down : up} alt="plus" className="cursor mx-2"/>
                            </div>
                        </div>
                        {show === '6stQ' && 
                        <p className="faq-desc">{t('gkeeper.faqAns6')}</p>
                        }
                        <div className="faqDivider"></div>
                        <div className="help-container">
                            <div className="d-flex justify-content-between" onClick={() => handleShow('7stQ')}>
                            <h4 style={show === '7stQ' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC" >{t('gkeeper.faqQues7')}</h4>
                            <img src={show === '7stQ' ? down : up} alt="plus" className="cursor mx-2"/>
                            </div>
                        </div>
                        {show === '7stQ' && 
                        <p className="faq-desc">{t('gkeeper.faqAns7')}</p>
                        }
                        <div className="faqDivider"></div>
                        <div className="help-container">
                            <div className="d-flex justify-content-between" onClick={() => handleShow('8stQ')}>
                            <h4 style={show === '8stQ' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC" >{t('gkeeper.faqQues8')}</h4>
                            <img src={show === '8stQ' ? down : up} alt="plus" className="cursor mx-2"/>
                            </div>
                        </div>
                        {show === '8stQ' && 
                        <p className="faq-desc">{t('gkeeper.faqAns8')}</p>
                        }
                        <div className="faqDivider"></div>
                    </Col>
                </Tab>
                <Tab eventKey={"Bank Link Up"} title={t('gkeeper.heading3')}>
                    <Col style={{ 'marginTop': '38px' }}>
                        <div className="faqDivider"></div>
                        <div className="help-container">
                            <div className="d-flex justify-content-between" onClick={() => handleShow('9stQ')}>
                                <h4 style={show === '9stQ' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC" >{t('gkeeper.faqQues9')}</h4>
                                <img src={show === '9stQ' ? down : up} alt="plus" className="cursor mx-2"/>
                            </div>
                        </div>
                        {show === '9stQ' && 
                        <p className="faq-desc">{t('gkeeper.faqAns9')}</p>
                        }
                        <div className="faqDivider"></div>
                        <div className="help-container">
                            <div className="d-flex justify-content-between" onClick={() => handleShow('10stQ')}>
                            <h4 style={show === '10stQ' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC" >{t('gkeeper.faqQues10')}</h4>
                            <img src={show === '10stQ' ? down : up} alt="plus" className="cursor mx-2"/>
                        </div>
                        </div>
                        {show === '10stQ' && 
                        <p className="faq-desc">{t('gkeeper.faqAns10')}</p>
                        }
                        <div className="faqDivider"></div>
                        <div className="help-container">
                        <div className="d-flex justify-content-between" onClick={() => handleShow('11stQ')}>
                            <h4 style={show === '11stQ' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC" >{t('gkeeper.faqQues11')}</h4>
                            <img src={show === '11stQ' ? down : up} alt="plus" className="cursor mx-2"/>
                            </div>
                        </div>
                        {show === '11stQ' && 
                        <p className="faq-desc">{t('gkeeper.faqAns11')}</p>
                        }
                        <div className="faqDivider"></div>
                    </Col>
                </Tab>
                <Tab eventKey={"Privacy and Security"} title={t('gkeeper.heading4')}>
                    <Col style={{ 'marginTop': '38px' }}>
                    <div className="faqDivider"></div>    
                    <div className="help-container">
                            <div className="d-flex justify-content-between" onClick={() => handleShow('12stQ')}>
                            <h4 style={show === '12stQ' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC" >{t('gkeeper.faqQues12')}</h4>
                            <img src={show === '12stQ' ? down : up} alt="plus" className="cursor mx-2"/>
                            </div>
                        </div>
                        {show === '12stQ' && 
                        <p className="faq-desc">{t('gkeeper.faqAns12')}</p>
                        }
                    <div className="faqDivider"></div>
                    <div className="help-container">
                        <div className="d-flex justify-content-between" onClick={() => handleShow('13stQ')}>
                            <h4 style={show === '13stQ' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC" >{t('gkeeper.faqQues13')}</h4>
                            <img src={show === '13stQ' ? down : up} alt="plus" className="cursor mx-2"/>
                        </div>
                    </div>
                    {show === '13stQ' && 
                    <p className="faq-desc">{t('gkeeper.faqAns13')}</p>
                    }
                    <div className="faqDivider"></div>
                    <div className="help-container">
                        <div className="d-flex justify-content-between" onClick={() => handleShow('14stQ')}>
                            <h4 style={show === '14stQ' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC" >{t('gkeeper.faqQues14')}</h4>
                            <img src={show === '14stQ' ? down : up} alt="plus" className="cursor mx-2"/>
                        </div>
                    </div>
                    {show === '14stQ' && 
                    <p className="faq-desc">{t('gkeeper.faqAns14')}</p>
                    }
                    <div className="faqDivider"></div>   
                    </Col>
                </Tab>
            </Tabs>
        </Col>
    </Row>
</Container>
  )
}

export default Faq