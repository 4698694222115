import React, { useContext, useState, useEffect } from 'react';
import './cashloan.css';
import { Container, Row, Col } from 'react-bootstrap';
import cashLoanBannerCn from './images/cashLoanBannerCn.svg';
import cashLoanBannerEn from './images/cashLoanBanner.svg';
import cashLoanMobileBannerCn from './images/cashLoanBannerMobileCn.png';
import cashLoanMobileBanner from './images/cashLoanBannerMobile.png';
import { useTranslation } from 'react-i18next';
import Number from '../../components/number/Number';
import tuFreeLoan from './images/tuFreeLoan.png';
import creditModel from './images/creditModel.png';
import step1 from './images/step1.svg';
import step2 from './images/step2.svg';
import step3 from './images/step3.svg';
import step4 from './images/step4.svg';
import arrow from './images/arrow.svg';
import appleImg from '../home/images/appleImg.svg';
import googleImg from '../home/images/googleImg.svg';
import {LanguageContext} from '../../context/LanguageContext';
import Howwork from '../../components/howworks/Howwork';
import ReactMarkdown from 'react-markdown';
import carloan from './images/carloan.svg';
import taxloan from './images/taxloan.svg';
// import hotelquarantine from './images/hotelquarantine.svg';
import medicalCard from './images/medicalCard.svg';
import studyloan from './images/studyloan.svg';
import Faq from '../faq/Faq';
import GetStarted from '../../components/getstarted/GetStarted';
import reloanPhone from './images/reloanPhone.png';
import reloanPhoneCn from './images/reloanPhoneCn.png';
import { useNavigate } from "react-router-dom";
import SEO from '../../SEO';
import Spacer from 'components/atoms/spacer';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
}


function CashLoan() {
  const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    // const [smallScreen, setSmallScreen] = useState(false);
    const navigate = useNavigate();

  const redirectLink = (link) => {
    if (['google', 'apple', 'web'].includes(link)) {
        window.open(window.AF_SMART_SCRIPT_RESULT.clickURL);
    }
    if (link === 'offers') {
        navigate('/promotions');
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

//   useEffect(() => {
//     if (windowDimensions.width <= 576) {
//       setSmallScreen(true);
//     }
//     if (windowDimensions.width >= 576) {
//       setSmallScreen(false)
//     }
//   }, [windowDimensions]);

  const bannerBkg = () => {
    if (windowDimensions.width < 768) {
        return language === 'zh' ? cashLoanMobileBannerCn : cashLoanMobileBanner;
    } else {
        return language === 'zh' ? cashLoanBannerCn : cashLoanBannerEn;
    }
  };

  const handleRedirect = () => {
    window.open(window.AF_SMART_SCRIPT_RESULT.clickURL, '_blank');
 }

  return (
      <div>
            <SEO title={t('cashLoan.pageTitle')} description={t('cashLoan.metaDescription')} /> 
            <Container fluid className="cashLoan p-0 overflow-hidden" data-aos="slide-down" data-aos-duration="700"
                style={{ backgroundImage: `url(${bannerBkg()})`}}>
                <Row className="justify-content-center h-100">
                    <Col sm={12} md={5} xs={12} className="px-4 my-auto">
                        <p className="cashloanHeading">{t('cashLoan.bannerHeading')}</p>
                        <p className="cashLoanrDesc">{t('cashLoan.bannerSubheading')}</p>
                        <p className="cashloanDetail"><ReactMarkdown children={t('cashLoan.bannerDetail')} /></p>
                        <div className="storeLinks">
                            <img src={appleImg} className="appStore" alt="appStore" onClick={() => handleRedirect()}/>                
                            <img src={googleImg} className="googlePlay" alt="playStore" onClick={() => handleRedirect()} />
                        </div>
                    </Col>
                    <Col sm={12} md={5} xs={12} className="d-flex justify-content-center">
                        {/* <img src={language === 'zh' ? cashLoanPhoneCn : cashLoanPhoneEn} alt="phone" className="cashloanImg" /> */}
                    </Col>
                </Row>
            </Container>
            <Container className="tu-loan-area">
                <Row>
                    <Col lg={4} sm={12} className="num">
                        <div className="text-center">
                            <p className="text-center approve">{t('freeLoan.fastLoan')}</p>
                            <Number prefix="$" start={0} end={40000} decimal={0} value={'^'} duration={1} />
                        </div>
                    </Col>
                    <Col lg={4} sm={12} className="num">
                        <div className="text-center">
                            <p className="text-center approve">{t('freeLoan.averageLoan')}</p>
                            <Number start={0} end={1} decimal={0} value={' '} duration={1}/>
                            <Number prefix=" - " start={0} end={36} decimal={0} value={t('freeLoan.time')} duration={1}/>
                        </div>
                    </Col>
                    <Col lg={4} sm={12} className="num">
                        <div className="text-center">
                            <p className="text-center approve">{t('freeLoan.APR')}</p>
                            <Number start={0} end={4.2} decimal={2} value="%" duration={1}/>
                        </div>
                    </Col>
                    <p className="figureDisc">{t('freeLoan.figures')}</p>
                </Row>
            </Container>
            <Container className="freeLoanSteps">
                <Row className="justify-content-center">
                    <h3 className="freeLoan-good" >{t('freeLoan.whyTuGood')}</h3>
                    <Col lg={5} md={12} sm={12}  xs={12} className="cashLoanLeft">
                        <div className="d-flex cashStepArea">
                            <h1 className="cashLoan-step">01</h1>
                            <div className="mx-3">
                                <p className="cashLoan-desc px-2">{t('freeLoan.step1Heading')}</p>
                                <p className="cashLoan-detail px-2">{t('freeLoan.step1')}</p>
                            </div>
                        </div>
                        <div className="d-flex cashStepArea">
                            <h1 className="cashLoan-step">02</h1>
                            <div className="mx-3">
                            <p className="cashLoan-desc">{t('freeLoan.step2Heading')}</p>
                            <p className="cashLoan-detail">{t('freeLoan.step2')}</p>
                            </div>
                        </div>
                        <div className="d-flex cashStepArea">
                            <h1 className="cashLoan-step">03</h1>
                            <div className="mx-3">
                            <p className="cashLoan-desc">{t('freeLoan.step3Heading')}</p>
                            <p className="cashLoan-detail">{t('freeLoan.step3')}</p>
                            </div>
                        </div>
                        <div className="d-flex cashStepArea">
                            <h1 className="cashLoan-step">04</h1>
                            <div className="mx-3">
                            <p className="cashLoan-desc">{t('freeLoan.step4Heading')}</p>
                            <p className="cashLoan-detail">{t('freeLoan.step4')}</p>
                            </div>
                        </div>
                        <div className="d-flex cashStepArea">
                            <p className="cashLoanReq">{t('freeLoan.requirement')}</p> 
                        </div>
                    </Col>
                    <Col lg={5} md={12} sm={12}  xs={12} className="tuLoanImg my-auto cashLoanRight">
                          <img src={tuFreeLoan} alt="tuFreeLoan" className="tuFreeLoan" />     
                    </Col>
                </Row>
            </Container>
            <Container className="specialLoans">
                <div className="aiExpArea">
                    <p className="AiExpHead">{t('SpecialLoans.title')}</p>
                    <p className="AiExpDesc">{t('SpecialLoans.desc')}</p>
                </div>
                <Row className="rowSpecialLoan justify-content-center">
                    <Col md={2} xs={11} className="specialLoanCard">
                        <img className="specialLoanImg" src={carloan} alt="carloan" />
                        <p className="specialLoanTitle">{t('SpecialLoans.carLoanTitle')}</p>
                        <p className="specialLoanDesc">{t('SpecialLoans.carLoanDesc')}</p>
                    </Col>
                    <Col md={2} xs={11} className="specialLoanCard">
                        <img className="specialLoanImg" src={taxloan} alt="taxloan" />
                        <p className="specialLoanTitle">{t('SpecialLoans.taxLoanTitle')}</p>
                        <p className="specialLoanDesc">{t('SpecialLoans.taxLoanDesc')}</p>
                    </Col>
                    <Col md={2} xs={11} className="specialLoanCard">
                        <img className="medicalLoanImg" src={medicalCard} alt="medicalLoan" />
                        <p className="specialLoanTitle">{t('SpecialLoans.medicalLoanTitle')}</p>
                        <p className="specialLoanDesc">{t('SpecialLoans.medicalLoanDesc')}</p>
                    </Col>
                    <Col md={2} xs={11} className="specialLoanCard">
                        <img className="specialLoanImg" src={studyloan} alt="studyloan" />
                        <p className="specialLoanTitle">{t('SpecialLoans.studyLoanTitle')}</p>
                        <p className="specialLoanDesc">{t('SpecialLoans.studyLoanDesc')}</p>
                    </Col>
                </Row>
                <p className="specialLoanDisclaimer">{t('SpecialLoans.disclaimer')}</p>
            </Container>
            <Container className="creditRating">
                <Row className="justify-content-center">
                    <Col sm={8} xs={12}>
                        <div className="aiExpArea">
                            <p className="AiExpHead">{t('creditModel.title')}</p>
                            <p className="AiExpDesc">{t('creditModel.desc')}</p>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center ">
                    <Col md={6} sm={12}  xs={12} className="tuLoanImg my-auto cashLoanRight">
                        <img src={creditModel} alt="tuFreeLoan" className="creditModelImg" />     
                    </Col>
                </Row>
            </Container>
            <Container fluid className="p-0 overflow-hidden loanApplicationProcess ">
                <Row className="justify-content-center ">
                    <Col lg={8} md={12} sm={12} className="justify-content-center">
                        <p className="applicationHead AiExpHead">{t('applicationProcess.title')}</p>
                    </Col>
                    <div className="cashHowWork ">
                        <Row className="justify-content-lg-center">
                            <Col className="d-flex justify-content-center " lg={2} sm={12} md={12}>
                                <Howwork img={step1} alt="step1" title={t('home.works.step1')} desc={t('home.works.step1Des')} />
                            </Col>
                            <Col className='my-auto d-lg-block d-none ' lg={1}><img src={arrow} alt="" /></Col>
                            <Col className="d-flex justify-content-center " lg={2} sm={12} md={12}>
                                <Howwork img={step2} alt="step2" title={t('home.works.step2')} desc={t('home.works.step2Des')} />
                            </Col>
                            <Col className='my-auto d-lg-block d-none' lg={1}><img src={arrow} alt="" /></Col>
                            <Col className="d-flex justify-content-center " lg={2} sm={12} md={12}>
                                <Howwork img={step3} alt="step3" title={t('home.works.step3')} desc={t('home.works.step3Des')} />
                            </Col>
                            <Col className='my-auto d-lg-block d-none' lg={1}><img src={arrow} alt="" /></Col>
                            <Col className="d-flex justify-content-center " lg={2} sm={12} md={12}>
                                <Howwork img={step4} alt="step4" title={t('home.works.step4')} desc={t('home.works.step4Des')} />
                            </Col>
                        </Row>
                    </div>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center ">
                    <Col md={8} sm={12} className="justify-content-center">
                        <p className="applicationHead AiExpHead">{t('reloan.title')}</p>
                        <p className="AiExpDesc">{t('reloan.desc')}</p>
                    </Col>
                    <img src={language === 'zh' ? reloanPhoneCn : reloanPhone} alt="reloan" className="reloanPhoneImg" />     
                </Row>
            </Container>
            <Faq />
            <div className="mt-5">
                <GetStarted desc={t('signUp.signDesc')} />
            </div>
    </div>
  )
}

export default CashLoan