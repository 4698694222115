import React, { useRef, useEffect, useContext } from 'react';
import { NumericFormat as NumberFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../../context/LanguageContext';
import rightArrow from '../step2/images/rightArrow.svg';
import {Col} from 'react-bootstrap';
import "./step1.css";

function Step1({ nextStep, handleFormData, values, step1Clear, prevStep }) {
    const { t } = useTranslation();  
    const { language } = useContext(LanguageContext);

    const nextRef = useRef();

    const ageRange = [...Array(83).keys()].map(i => i + 18);

    useEffect(() => {
        step1Clear ? nextRef.current.style.background = "linear-gradient(to left ,rgba(0, 52, 90, 1),rgba(51, 103, 141, 1))" : nextRef.current.style.background = "#C0C3C6";

    }, [step1Clear])

    function handleNext() {
        nextStep();
    }

    let selectClass = values.age === '1' ? 'selectDefault' : 'selectAge';
    let selectWidth = values.age === '1' ? '134px' : '60px';

    return (
        <>
            <div className="step1Input">
                <div className="d-flex">
                    <div>
                        <h5>{t('home.calculator.step1Age')}</h5>
                    </div>
                    <div className="d-flex">
                        <div>
                                <select value={values.age} name="age" 
                                    style={{ width: `${selectWidth}` }} 
                                    onChange={handleFormData("age")}
                                    className={`stepInput ${selectClass}`}>
                                    <option value="1" disabled default>
                                        {t('home.calculator.selectAge')}  
                                    </option>
                                    {
                                        ageRange.map((yr, i) => {
                                            return <option key={`year${i}`} value={yr}>{yr}</option>
                                        })
                                    }
                                </select>
                        </div>
                        <div>
                            {language === 'zh' ?
                            <span style={{ "font-weight": "500", "line-height": "0px", "font-size": "20px"}}>歲</span>
                            : null}
                        </div>
                        <div className="semicolon"><p>;</p></div>
                    </div>
                </div>
                <div className="d-flex income">
                    <div>
                        <h5>{t('home.calculator.step1Income')}</h5>
                    </div>
                    <div className="d-flex">
                        <div className="amountInput">
                            <label className={values.monthlyIncome ? "dollar dollarChng" : "dollar"}>$</label>
                            <NumberFormat
                                value={values.monthlyIncome}
                                className="stepInput"
                                onChange={handleFormData("monthlyIncome")}
                                thousandSeparator={true}
                                name="monthlyIncome"
                            />
                        </div><div className="semicolon"><p>;</p></div>
                    </div>
                </div>
            </div>
            <div className="step1Input">
                <div className="d-flex">
                    <div>
                        <h5>{t('home.calculator.step1Expense')}</h5>
                    </div>
                    <div className="d-flex">
                        <div className="amountInput">
                            <label className={values.monthlyExpense ? "dollar dollarChng" : "dollar"}>$</label>
                            <NumberFormat
                                value={values.monthlyExpense}
                                className="stepInput"
                                onChange={handleFormData("monthlyExpense")}
                                thousandSeparator={true}
                                name="monthlyExpense"
                            />
                        </div>
                        { language === 'zh' && <div className="semicolon"><p>;</p></div>}
                    </div>
                </div>
                <div className="d-flex income bottomInput">
                    <div>
                        <h5>{t('home.calculator.step1Saving')}</h5>
                    </div>
                    <div className="d-flex">
                        <div className="amountInput">
                            <label className={values.totalSavings ? "dollar dollarChng" : "dollar"}>$</label>
                            <NumberFormat
                                value={values.totalSavings}
                                className="stepInput"
                                onChange={handleFormData("totalSavings")}
                                thousandSeparator={true}
                                name="totalSavings"
                            />
                        </div><div className="semicolon"><p>.</p></div>
                    </div>
                </div>
            </div>
            {/* <div className="nextStep">
                <div onClick={step1Clear === true ? handleNext : undefined} ref={nextRef} className="next">{t('home.calculator.next')}</div>
            </div> */}
            <Col lg={12} className="d-flex justify-content-end">
            <div className="step2Btns">
                <div className="backbtn" onClick={prevStep}><h5>{t('home.calculator.back')}</h5></div>
                <div className="ranking" onClick={step1Clear ? handleNext : undefined} ref={nextRef}>
                    <h5 className="rankingBtn">{t('home.calculator.checkResult')}</h5>
                    <img width="18px" src={rightArrow} alt="rightArrow" className="rankingArrow" />
                </div>
            </div>
            </Col>
        </>
    )
}

export default Step1

