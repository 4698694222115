import React from 'react'
import './howwork.css';

function Howwork({img, title, desc}) {
    return (
        <div className="howwork" data-aos='fade' data-aos-delay="50" data-aos-duration="500" >
             <div className="d-flex justify-content-center"><img className="howWorkImg" src={img} alt=""/></div>
            <p className="text-center">{title}</p>
            <h6 className="text-center">{desc}</h6>
        </div>
    )
}

export default Howwork
