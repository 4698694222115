import React from 'react';
import RBModal from 'react-bootstrap/Modal';

import styles from './styles.module.scss';

// children: React.Node
// show: bool
function Modal({ children, ...props }) {
  return (
    <RBModal
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      centered
      size="lg"
      {...props}
    >
      {children}
    </RBModal>
  );
}

export default Modal;
