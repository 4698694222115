import React, { useRef, useEffect, useState } from 'react';
import "./step3.css";
import rightArrow from '../step2/images/rightArrow.svg';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';

function Step3({ prevStep, values, setFormData, step3Clear, submitData, error }) {

    const [isLoading, setLoading] = useState(false);

    const step3Ref = useRef();
    const { t } = useTranslation();  

    useEffect(() => {
        step3Clear ? step3Ref.current.style.background = "linear-gradient(to left ,rgba(0, 52, 90, 1),rgba(51, 103, 141, 1))" : step3Ref.current.style.background = "#C0C3C6";
    });

    useEffect(() => {
        if (error) {
            setLoading(false);
        }
    }, [error]);

    const handleInput = input => e =>{
        const { value } = e.target;
        const re = /^[0-9]+$/; 
        if (value === "" || re.test(value)) {
            setFormData(prevState => ({
                ...prevState,
                [input]: value
            }));
        }  
      }

    const FinalSubmit = () => {
        setLoading(true);
        submitData();
    }  
   
      
    return (
        <>
        {error && <p className="errorMsg">{error === 'ValidationError' ? t('home.calculator.duplicateNoError') : t('home.calculator.otherError')}</p>}
        <h5 className="lifeGoal">{t('home.calculator.inputMobileNo')}</h5>
        <Col lg={4} sm={12}>
        <div className="inputNo inputArea">
            <p className="countrycode my-auto">+852</p>
            <input name="mobileno" maxLength="8" value={values.mobileno} placeholder={t('home.calculator.mobileNo')} className="numberInput my-auto mx-2" onChange={ handleInput("mobileno")}   />
        </div>
        </Col> 
        <p className="contactInfo">
            {t('home.calculator.step3Disclaimer')}
        </p> 
        <Col className="d-flex justify-content-end">
        <div className="step2Btns">
                <div className="backbtn" onClick={prevStep}><h5>{t('home.calculator.back')}</h5></div>
                <div className="finalSubmit" onClick={step3Clear ? FinalSubmit : undefined} ref={step3Ref}>
                    {isLoading ? 
                        (
                            <div className="loadingSpinner">
                                <Spinner as="span" size="sm" aria-hidden="true" animation="border" variant="light"/>
                            </div>
                        )  :
                        (
                            <>
                                <h5 className="rankingBtn">{t('home.calculator.submit')}</h5>
                                <img width="18px" src={rightArrow} alt="rightArrow" className="rankingArrow" />   
                            </>
                        )
                    }
                </div>
            </div> 
            </Col>     
        </>
    )
}

export default Step3

