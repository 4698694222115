import React, { useCallback, useContext, useState, useEffect } from 'react';
import './dealdetails.css';
import { useLocation } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import back from './images/back.svg';
import copy from './images/copy.svg';
import arrow from '../landing/images/arrow.svg';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/zh-hk';

dayjs.extend(localizedFormat)
dayjs.extend(advancedFormat)

const DealDetails = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { state } = location;
    const { language } = useContext(LanguageContext);
    const lang = language && language === 'zh' ? 'zh-Hant-HK' : 'en';
    const [dealDetail, setDealDetail] = useState({});
    const [dateInWord, setDateInWord] = useState('');
    const [isCopy, setIsCopy] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (language === 'zh') {
            dayjs.locale('zh-hk')
        } else {
            dayjs.locale('en')
        }
    }, [language]);

    const getDeals = useCallback(async () => {
        const langParam = state && state.slugRedirect ? 'all' : lang;
        await fetch(`${process.env.REACT_APP_CMS_APP_URL}/api/deals?locale=${langParam}&pagination[pageSize]=100&sort=id:DESC`)
            .then(response => response.json())
            .then(data => {
                const dealsList = data.data;
                dealsList.forEach(deal => {
                    const attributes = deal.attributes;
                    const { state } = location;
                    if (attributes.slug === state.slugRedirect) {
                        let { category, title, shortDescription, longDescription, bannerImgLink, thumbNailImgLink, dealExpiryDate, imgCaption, termsAndConditions, promoCode, link, buttonText } = attributes;
                        setDealDetail({ category, title, shortDescription,longDescription, bannerImgLink, thumbNailImgLink, dealExpiryDate, imgCaption, termsAndConditions, promoCode, link, buttonText });
                        const dateObj = new Date(dealExpiryDate);
                        setDateInWord(language === 'zh' ? dayjs(dateObj).format('LL') : dayjs(dateObj).format('Do MMMM, YYYY'));
                    }
                });
            }).catch((err) => {
                console.log(err);
            });

    }, [lang, location, state, language]);

    useEffect(() => {
        if (state) {
            if (state.attributes) {
                const { attributes } = state;
                let { category, title, shortDescription, longDescription, bannerImgLink, thumbNailImgLink, dealExpiryDate, imgCaption, termsAndConditions, promoCode, link, buttonText } = attributes;
                setDealDetail({ category, title, shortDescription, longDescription, bannerImgLink, thumbNailImgLink, dealExpiryDate, imgCaption, termsAndConditions, promoCode, link, buttonText });
                const dateObj = new Date(dealExpiryDate);
                setDateInWord(language === 'zh' ? dayjs(dateObj).format('LL') : dayjs(dateObj).format('Do MMMM, YYYY'));
            } else if (state.slugRedirect) {
                getDeals();
            }
        }
    }, [state, getDeals, language]);

    const handleCopy = () => {
        navigator.clipboard.writeText(dealDetail.promoCode);
        setIsCopy(true);
        setTimeout(() => {
            setIsCopy(false);
        }, 3000);
    }

    const { bannerImgLink, imgCaption, title, longDescription, link, termsAndConditions, buttonText, promoCode } = dealDetail;
    return (
        <div>
            <div className="mobileBanner">
                <img src={bannerImgLink} alt={imgCaption} className="dealBannerMobImg" />
            </div>
            <Container className="mobileprops">
                <Row className="dealDetail-margin">
                    <div className="back">
                        <img src={back} className="backIcon" alt="back" />
                        <span onClick={() => navigate('/exclusive-deals')}>
                            {t('deals.back')}
                        </span>
                    </div>
                </Row>
                <Row>
                    <Col md={12} lg={5} className="bannerCol">
                        <div className="dealBanner">
                            <img src={bannerImgLink} alt={imgCaption} className="dealBannerImg" />
                        </div>
                    </Col>
                    <Col sm={12} lg={6} className="dealDetail">
                        <span className="dealExpiry">{t('deals.promotionEnds')} {dateInWord}</span>
                        <h1>{title}</h1>
                        <div className="dealDetailDesc">
                            <ReactMarkdown children={longDescription} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}/>

                        </div>
                        <div className="promoDetail">
                            {promoCode && (
                                <>
                                    <p>{t('deals.promoCode')}</p>
                                    <div className="promoCode" onClick={handleCopy}>
                                        <p>{promoCode}</p>
                                        <img src={copy} alt="copy" />
                                    </div>
                                </>
                            )}
                            <Button
                                variant="primary"
                                className="dealDetailButton"
                                key={link}
                                onClick={() => window.open(link)}
                            >
                                {buttonText}
                            </Button>
                            <Button
                                variant="primary"
                                className="mobileOtherOffers"
                                key={'otherOffers'}
                                onClick={() => navigate('/exclusive-deals')}
                            >
                                {t('deals.otherOffers')}
                            </Button>
                        </div>
                        <hr style={{'marginTop': '40px'}}></hr>
                        <div className="dealTnC">
                            <ReactMarkdown children={termsAndConditions} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} />
                        </div>
                    </Col>
                    <Col sm={12} lg={1} />
                </Row>
            </Container>
            {isCopy &&
                <div className="copyToast">
                    <div className="toastContent">
                        <img src={arrow} alt="arrow" className="my-auto" />
                        <p className="link-copied my-auto">{t('deals.copy')}</p>
                    </div>
                </div>
            }
        </div>
    )
};

export default DealDetails;
