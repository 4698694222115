import React from 'react';
import './feature.css'

function Feature({img, heading, desc}) {
    return (
        <div className="feature" data-aos='fade' data-aos-duration="500">
            <div className="featureImg">
            <img className="feature-img" src={img} alt=""/>
            </div>
            <div className="feature-content mx-4">
                <h4>{heading}</h4>
                <p>{desc}</p>
            </div>
        </div>
    )
}

export default Feature
