import React, { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import { useTranslation } from 'react-i18next';
import { Container, Col, Row } from 'react-bootstrap';
import './calculator.css';
import calculatorImage from './images/calculatorImage.png';
import Calculator from '../../components/expensecalculator/Calculator';
import Howwork from '../../components/howworks/Howwork';
import financialAdvice from './images/financialAdvice.png';
import hkAverage from './images/hkAverage.png';
import goalsTimeline from './images/goalsTimeline.png';
import GetStarted from '../../components/getstarted/GetStarted';
import SEO from '../../SEO';

function CalculatorPage () {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);

    return (
        <>
            <SEO title={t('calculatorPage.pageTitle')} description={t('calculatorPage.metaDescription')} /> 
            <div className="calculatorBanner" data-aos="slide-down" data-aos-duration="700">
                <Container className="p-100 overflow-hidden ">
                    <Row className="justify-content-center ">
                        <Col sm={12} md={12} lg={5} xs={12} className="my-auto h-100">
                            <p className="calcBannerHeading">{t('calculatorPage.title')}</p>
                            <p className="calcBannerDesc ">{t('calculatorPage.desc')}</p>
                        </Col>
                        <Col sm={12} md={12} lg={5} xs={12} className="d-flex justify-content-center">
                            <img src={calculatorImage} alt="calculator" className="calculatorImage" />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container fluid className={`p-0 overflow-hidden ${language === 'zh' ? 'calcCompCn' : 'calcCompEn'}`}>
                <Row className="justify-content-center mt-5" data-aos='fade' data-aos-duration="700">
                    <Col lg={10} >
                        <Calculator />
                    </Col>
                </Row>
                <div className="benefits">
                    <Row className="justify-content-center my-5">
                        <Col className="d-flex justify-content-center marginImg" lg={3} sm={12} md={6}>
                            <Howwork img={financialAdvice} alt="advice" title={t('calculatorPage.finAdvice')} />
                        </Col>
                        <Col className="d-flex justify-content-center marginImg" lg={3} sm={12} md={6}>
                            <Howwork img={hkAverage} alt="advice" title={t('calculatorPage.hkAv')} />
                        </Col>
                        <Col className="d-flex justify-content-center marginImg" lg={3} sm={12} md={6}>
                            <Howwork img={goalsTimeline} alt="advice" title={t('calculatorPage.goals')} />
                        </Col>
                    </Row>
                    <div className="getStarted">
                        <GetStarted  desc={t('signUp.signDesc')}/>
                    </div>
                </div>
            </Container>
        </>
    );

}

export default CalculatorPage