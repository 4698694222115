import React from 'react';
// import { useLocation } from 'react-router-dom';
// import ReactGA from 'react-ga';
import { Container, Row, Col } from 'react-bootstrap';
import './aboutus.css';
import about_1 from './images/about_1.png';
import grantit from './images/grantit.png';
import logo_vertical from './images/logo_vertical.svg';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import SEO from '../../SEO';

function AboutUs() {
    const { t } = useTranslation();

    // let location = useLocation();

    // useEffect(() => {
    //   const { pathname } = location;
    //   ReactGA.set({ page: pathname });
    //   ReactGA.pageview(pathname);
    // }, [location]);

    return (
        <>
        <SEO title={t('aboutUs.pageTitle')} description={t('aboutUs.metaDescription')} />
        <Container fluid className="aboutUs-banner" data-aos='slide-down'  data-aos-duration="1000">
            <Row>
                <Col md={8} sm={12}>
                    <Row className="justify-content-center">
                        <Col md={7} sm={12} className="about-content" data-aos='fade' data-aos-delay="1000"  data-aos-duration="1000">
                            <h1>{t('aboutUs.title')}</h1>
                            <p>{t('aboutUs.desc')} </p>
                            <p>{t('aboutUs.desc2')}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        <Container fluid className="px-0 overflow-hidden">
        <Row className=" vision">
                <Col md={6} sm={12} className="d-flex justify-content-start about1Img px-5" data-aos='slide-right' data-aos-duration="500">
                    <img src={about_1} alt="about_1" className="about_1" />
                </Col>
                <Col md={5} sm={12} className="our-vision px-4" data-aos='fade' data-aos-duration="500">
                    <h2>{t('aboutUs.ourVision')}</h2>
                    <p><ReactMarkdown children={t('aboutUs.ourVisionDesc')} /></p>
                </Col>
            </Row>
        </Container>
        <Container fluid className="about_us_midBanner overflow-hidden">
            <Row>
                <Col md={7} sm={12} className="about_mid">
                    <Row className="justify-content-end">
                        <Col md={9} sm={12} className="our_values" data-aos='fade' data-aos-duration="500">
                            <h2>{t('aboutUs.ourValues')}</h2>
                            <p className="our_values_para">{t('aboutUs.ourValuesDesc')}</p>
                            <Row className="paragraphs">
                                <Col md={6} sm={12} data-aos='fade' >
                                   <h5 className="para-heading">{t('aboutUs.productFirst')}</h5>
                                   <p className="para-desc">{t('aboutUs.productFirstDesc')}</p>
                                </Col>
                                <Col md={6} sm={12} data-aos='fade'  >
                                   <h5 className="para-heading">{t('aboutUs.customerCentric')}</h5>
                                   <p className="para-desc">{t('aboutUs.customerCentricDesc')}</p>
                                </Col>
                            </Row>
                            <Row className="paragraphs2">
                                <Col md={6} sm={12} data-aos='fade' >
                                   <h5  className="para-heading">{t('aboutUs.simplicity')}</h5>
                                   <p className="para-desc">{t('aboutUs.simplicityDesc')}</p>
                                </Col>
                                <Col md={6} sm={12} data-aos='fade'  >
                                   <h5  className="para-heading">{t('aboutUs.dataDriven')}</h5>
                                   <p className="para-desc">{t('aboutUs.dataDrivenDesc')}</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col md={5} sm={12} fluid className="my-auto" data-aos='zoom-in' data-aos-duration="500">
                    <img src={grantit} alt="grantit" className="grantit1 pt-5" />
                </Col>
            </Row>
        </Container>
        <Container >
            <Row className="aboutUs-bottom p-0">
                <Col md={4} sm={12} className="d-flex justify-content-center my-auto" data-aos='fade p-0' data-aos-duration="500">
                    <img src={logo_vertical} alt="logo_vertical" className="logo_vertical"/>
                </Col>
                <Col md={7} sm={12} data-aos='fade p-0' data-aos-duration="500">
                    <div className="hero">
                        <h2>{t('aboutUs.meetOurBrand')}</h2>
                        <p style={{'marginTop': '20px'}}><ReactMarkdown children={t('aboutUs.meetOurBrandDesc')} /></p>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default AboutUs
