import clss from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import Typography from 'components/atoms/typography';

import styles from './styles.module.scss';

const MAX_LOADING_TIME = 3 * 1000; // 3s

function Loading() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const timeRef = setTimeout(() => {
      const result = localStorage.getItem('eligibilityResult');
      navigate(`/features/cashloan/eligibility-result?result=${result}`, {
        replace: true,
      });
    }, MAX_LOADING_TIME);
    return () => {
      clearTimeout(timeRef);
    };
  }, [navigate]);

  return (
    <div className={styles.background}>
      <div className={styles.stack}>
        <Typography
          wrap
          size="xh1"
          weight="medium"
          color="heading"
          align="center"
        >
          {t('application.loading.title')}
          <span className={clss(styles.dot, styles.dot1)}>.</span>
          <span className={clss(styles.dot, styles.dot2)}>.</span>
          <span className={clss(styles.dot, styles.dot3)}>.</span>
        </Typography>
        <Typography
          size="title1"
          weight="normal"
          color="inactive"
          wrap={true}
          align="center"
        >
          {t('application.loading.remaining')}
        </Typography>
      </div>
    </div>
  );
}

export default Loading;
