import React, { useMemo } from 'react';
import Steps from 'components/atoms/steps';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

function StepLayout({ children, activeIndex = 0, steps }) {
  const { t } = useTranslation();
  const list = useMemo(
    () =>
      steps || [
        t('cashLoan.application.stepOne'),
        t('cashLoan.application.stepTwo'),
      ],
    [steps, t]
  );
  return (
    <div className={styles.container}>
      <div className={styles.center}>
        <div className={styles.left}>
          <Steps activeIndex={activeIndex} list={list} />
        </div>
        <div className={styles.right}>{children}</div>
      </div>
    </div>
  );
}

export default StepLayout;
