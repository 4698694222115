import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

function Number({ prefix, start, end, decimal, value, duration }) {
    const [focus, setFocus] = useState(false);
    return (
        <>
            <CountUp prefix={prefix ? prefix : ''} start={focus ? start : null} end={end} duration={duration ? duration : 2} decimals={decimal} className="numbers" separator="," suffix={value} >
                {({ countUpRef }) => (
                    <VisibilitySensor onChange={(isVisible) => {
                        if (isVisible) { setFocus(true); }
                    }}>
                        <span ref={countUpRef} />
                    </VisibilitySensor>
                )}
            </CountUp>
        </>
    )
}

export default Number
