const toSalt = (index = 0) => {
  if (index % 3 === 0) {
    return 0;
  }
  if (index % 2 === 0) {
    // A[65] - 1[49]
    return 16;
  }
  // a[97] - 1[49]
  return 48;
};

// for encrypt phonenumber
export const encrypt = (num = '') =>
  // demo: 123 => 'Ab3' => base64
  btoa(
    String.fromCharCode(
      ...`${num}`.split('').map((n, index) => n.charCodeAt() + toSalt(index))
    )
  );

// for decrypt phonenumber
export const decrypt = (str = 'base64') =>
  // demo: base64 => 'Ab3' => 123
  String.fromCharCode(
    ...atob(str)
      .split('')
      .map((n, index) => n.charCodeAt() - toSalt(index))
  );
