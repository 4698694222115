import React from 'react';
import clss from 'classnames';

import styles from './primary.module.scss';

// fullSize: lg or true
function PrimaryButton({
  onClick,
  children,
  fullSize,
  disable = false,
  loading = false,
  className,
}) {
  return (
    <div
      className={clss(
        styles.button,
        {
          [styles.fullSize]: fullSize === true,
          [styles.lgFullSize]: fullSize === 'lg',
          [styles.disable]: !!disable,
          [styles.loading]: !!loading,
        },
        className
      )}
      onClick={!loading && !disable ? onClick : undefined}
    >
      {children}
    </div>
  );
}

export default PrimaryButton;
