import React, { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import { Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import './deal.css';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const Deal = ({ deal, isSelected }) => {
    const { language } = useContext(LanguageContext);

    const { attributes: { category, title, slug, shortDescription, thumbNailImgLink, imgCaption, buttonText, link } } = deal;
    const navigate = useNavigate();

    const dealDetail = () => {
        navigate(`/exclusive-deals/deal_details?slug=${slug}`, { state: deal });
    }

    const openWindow = (e, link) => {
        e.stopPropagation();
        window.open(link);
    };

    const getHtmlTagsLength = (str) => {
        const regex = /<[^>]+>/g;
        const tags = str.match(regex);
        if (tags) {
            let length = 0;
            tags.forEach(tag => {
                length += tag.length;
            });
            return length;
        }
        return 0;
    };

    const tagLength = getHtmlTagsLength(shortDescription);
    const charSize = tagLength + (language === 'zh' ? 75 : 150);
    const shortDesc = shortDescription.length > charSize ? `${shortDescription.substring(0, charSize)}...` : shortDescription;
    return (
        <>
            <Col lg={4} md={6} sm={12} className="p-0 dealCard" onClick={dealDetail}>
                <div className="">
                    <img src={`${thumbNailImgLink}`} className="dealImg" alt={imgCaption} />
                </div>
                <div className="deal-content">
                    <p className="dealCategoryText">{category}</p>
                    <h6 className="dealTitle" >{title}</h6>
                    <p className="dealDesc" >
                        <ReactMarkdown 
                            remarkPlugins={[remarkGfm]} 
                            rehypePlugins={[rehypeRaw]}
                            components={{
                                a: props => (
                                    props.href && <a href={props.href} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>{props.children}</a> 
                                )
                            }}
                        >
                            {shortDesc}
                        </ReactMarkdown>
                    </p>
                    <Button
                        variant="primary"
                        className="dealButton"
                        key={link}
                        onClick={(e) => openWindow(e, link)}
                    >
                        {buttonText}
                    </Button>          
                </div>
            </Col>
        </>
    )
};

export default Deal;