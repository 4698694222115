import { useCallback } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Phoneinput from 'components/form/phoneinput';
import Typography from 'components/atoms/typography';
import Button from 'components/atoms/button';

import { encrypt } from 'utils/Encrypted';
import useAxios from 'hooks/useAxios';

import { PhoneFormSchema } from './const';

function SignUp() {
  const navigate = useNavigate();
  const [initPhoneNumber, submitPhoneNumber] = useOutletContext();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: { phoneNumber: initPhoneNumber },
    resolver: yupResolver(PhoneFormSchema),
  });

  const {
    isLoading: checkUserLoading,
    error: checkUserError,
    mutate: checkUserExist,
  } = useAxios({
    url: '/users/checkExistence',
    method: 'POST',
    server: 'auth',
    enabled: false,
  });

  const {
    isLoading: sendOtpLoading,
    error: sendOtpError,
    mutate: sendOtp,
  } = useAxios({
    server: 'auth',
    url: '/otp/send',
    method: 'POST',
    enabled: false,
  });

  const onSubmit = useCallback(
    async ({ phoneNumber }) => {
      // save phone number to context
      submitPhoneNumber(phoneNumber);

      const prevSendCodeTime = parseInt(localStorage.getItem('sct'));

      if (
        !isNaN(prevSendCodeTime) &&
        Date.now() - prevSendCodeTime < 60 * 1000
      ) {
        // prevent users from clicking submit multiple times to send SMS
        return navigate('/features/cashloan/sign-up/otp', { replace: true });
      }

      const checkUserResponse = await checkUserExist({ data: { phoneNumber } });
      if (
        !checkUserResponse ||
        checkUserResponse.status !== 201 ||
        checkUserResponse.data?.exist == null
      ) {
        // got some error
        return;
      }
      if (checkUserResponse?.data?.isUserExist === true) {
        navigate('/features/cashloan/existing-user', { replace: true });
        return;
      }
      const sendOtpResponse = await sendOtp({ data: { phoneNumber } });
      if (sendOtpResponse?.status === 201) {
        // send-code-time = sct
        localStorage.setItem('sct', Date.now());
        localStorage.setItem('ph', encrypt(phoneNumber));
        navigate('/features/cashloan/sign-up/otp', { replace: true });
      }
    },
    [checkUserExist, sendOtp, submitPhoneNumber, navigate]
  );

  return (
    <>
      <Typography size="h1" weight="semibold" color="title">
        {t('application.signUp.title')}
      </Typography>
      <Typography
        size="h3"
        wrap={true}
        weight="normal"
        color="inactive"
        align="center"
      >
        {t('application.signUp.description')}
      </Typography>
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field }) => (
          <Phoneinput
            {...field}
            autoFocus={true}
            format="#### ####"
            error={!!errors?.phoneNumber && t(errors.phoneNumber.message)}
          />
        )}
      />
      <Button.Primary
        fullSize="lg"
        disable={!isValid}
        loading={sendOtpLoading || checkUserLoading}
        onClick={handleSubmit(onSubmit)}
      >
        <Typography size="h4" weight="semibold" color="white">
          {t('application.signUp.submit')}
        </Typography>
      </Button.Primary>
      {(!!checkUserError?.message || !!sendOtpError?.message) && (
        <Typography size="title2" color="error" wrap>
          {checkUserError?.message ?? sendOtpError?.message}
        </Typography>
      )}
    </>
  );
}

export default SignUp;
