import React from 'react';
import './card.css';

function Card({title, desc, img, name, star1, star2, star3, star4, star5}) {
    return (
        <div className="card"> 
            <h6>{title}</h6>
            <p>{desc}</p>
            <div className="intro">
            <ul className="mt-4 list-unstyled">
                <li className="star"><img src={star1}  alt="star" /></li>
                <li className="star"><img src={star2}  alt="star" /></li>
                <li className="star"><img src={star3}  alt="star" /></li>
                <li className="star"><img src={star4}  alt="star" /></li>
                <li className="star"><img src={star5}  alt="star" /></li>
            </ul>
            <div className="bio d-flex">
                <img className="thumbnil" src={img} alt='thumbnil' />
                <div className="flex mx-3">
                    <h6>{name}</h6>
                    <p className="app-store">from App Store</p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Card
