import React, { useEffect, useState, useContext } from 'react';
// import { useLocation } from 'react-router-dom';
// import ReactGA from 'react-ga';
import './blogs.css';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Blog from '../blog/Blog';
import GetStarted from '../../components/getstarted/GetStarted';
import Spiner from '../../components/spinner/Spiner';
import {LanguageContext} from '../../context/LanguageContext';
import Button from 'react-bootstrap/Button';
import qs from 'qs';
import SEO from '../../SEO';

function Blogs() {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const [blogs, setBlogs] = useState([]);
    const [blogCategories, setBlogCategories] = useState(new Set());
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [blogsPerPage] = useState(100);
    const lang = language && language === 'zh' ? 'zh-Hant-HK' : 'en';

    // const indexOfLastBlog = currentPage * blogsPerPage;
    // const indexOfirstBlog = indexOfLastBlog - blogsPerPage;
    // const currentBlogs = blogs && blogs.slice(indexOfirstBlog, indexOfLastBlog);

    useEffect(() => {
        setBlogCategories(new Set());
        blogs.forEach(blog => {
            setBlogCategories(prev => new Set(prev).add(blog.attributes.category));
        });
    }, [blogs]);

    const selectCategory = async (category) => {
        if (!selectedCategories.includes(category)) {
            setSelectedCategories(prev => [...prev, category]);
        } else {
            setSelectedCategories(prev => prev.filter((item) => item !== category))
        }
    };

    useEffect(() => {
        const filterBlogsByCategory = async (categories) => {
            setLoading(true);
            let query = ''
            if (categories.length > 0) {
                query = qs.stringify({
                    filters: {
                        category: {
                            $in: categories
                        }
                    }
                }, {
                    encodeValuesOnly: true
                });
            }
            await fetch(`${process.env.REACT_APP_CMS_APP_URL}/api/blogs?locale=${lang}&sort=id:DESC&pagination[pageSize]=100&${query}`)
                .then(response => response.json())
                .then(data => {
                    setBlogs(data.data);
                    setLoading(false);
                }).catch((err) => {
                    console.log(err);
                });
        };

        filterBlogsByCategory(selectedCategories);

    }, [selectedCategories, lang])

    // const paginate = (pageNumber) => {
    //     setCurrentPage(pageNumber)
    // };

    // let location = useLocation();

    // useEffect(() => {
    //   const { pathname } = location;
    //   ReactGA.set({ page: pathname });
    //   ReactGA.pageview(pathname);
    // }, [location]);


    return (
        <>
            <SEO title={t('blog.pageTitle')} description={t('blog.metaDescription')} />
            <Container>
                <Row className="blogs-margin">
                    <Col lg={12} className="blogs">
                        <Row>
                            <Col md={10} sm={12}>
                                <h1>{t('blog.title')}</h1>
                                <p>{t('blog.desc')}</p>
                            </Col>
                        </Row>
                    </Col>
                    {blogCategories && blogCategories.size > 0 &&
                        Array.from(blogCategories).map(category => {
                            return (
                                <Button 
                                    variant="outline-primary" 
                                    className="mx-2 my-3 categoryButton" 
                                    key={category}
                                    active={selectedCategories.includes(category)}
                                    onClick={() => selectCategory(category)}>{category}</Button>
                            )
                        })                        
                    }
                    {loading ? <div style={{'marginTop': '-150px'}}> <Spiner /> </div> : 
                            <div style={{ 'marginBottom': "0px" }} className="blogslist">
                            <Row>
                                {blogs && blogs.map(blog => (
                                    <Blog blog={blog} key={blog.id} />
                                ))
                                }
                            </Row>
                        </div>
                    }
                </Row>
            </Container>
            <div className="mt-5">
                <GetStarted desc={t('signUp.signDesc')} />
            </div>
        </>
    )
}

export default Blogs
