import React from 'react';
import clss from 'classnames';

import styles from './ghost.module.scss';

function GhostButton({ onClick, children, loading = false, className }) {
  return (
    <div
      className={clss(
        styles.button,
        { [styles.loading]: !!loading },
        className
      )}
      onClick={!loading ? onClick : undefined}
    >
      {children}
    </div>
  );
}

export default GhostButton;
