import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import arrowUp from '../../components/gkeeperfaq/images/arrowSecondUp.svg';
import arrowDown from '../../components/gkeeperfaq/images/arrowSecondDown.svg';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';


function LoanApplication() {
    const [show, setShow] = useState('');
    const { t } = useTranslation();

    const handleShow = (action) => {
        if (action === 'application' || 'approval' || 'transfer' || 'reloan' || 'rejection' || 'loan' || 'bankrupt' || 'status' || 'borrow' || 'grantit') {
            setShow((prev) => prev === action ? '' : action);
        }
    }
 
    return (
        <>
            <Col style={{ 'marginTop': '38px' }}>
                <div className="faqDivider"></div>
                <div className="help-container ">
                    <div  className="d-flex justify-content-between" onClick={() => handleShow('application')}>
                        <h4 style={show === 'application' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.loanTab.applicationTab')}</h4>
                        <img src={show === 'application' ? arrowDown : arrowUp} alt="plus" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'application' &&
                    <div >
                        <p className="faq-desc"><ReactMarkdown children={t('faq.loanTab.applicationTabDesc')}  /></p>
                    </div>
                }
            </Col>
            <Col>
                <div className="faqDivider"></div>
                <div className="help-container " >
                <div  className="d-flex justify-content-between" onClick={() => handleShow('approval')}>
                        <h4 style={show === 'approval' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.loanTab.timeTab')}</h4>
                        <img  src={show === 'approval' ? arrowDown : arrowUp} alt="plus" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'approval' &&
                    <div >
                        <p className="faq-desc"><ReactMarkdown children={t('faq.loanTab.timeTabDesc')}  /></p>
                    </div>
                }
            </Col>
            <Col>
                <div className="faqDivider"></div>
                <div className="help-container " >
                <div  className="d-flex justify-content-between" onClick={() => handleShow('transfer')}>
                        <h4 style={show === 'transfer' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.loanTab.transferTab')}</h4>
                        <img src={show === 'transfer' ? arrowDown : arrowUp} alt="plus" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'transfer' &&
                    <div >
                        <p className="faq-desc"><ReactMarkdown children={t('faq.loanTab.transferTabDesc')}  /></p>
                    </div>
                }
            </Col>
            <Col>
                <div className="faqDivider"></div>
                <div className="help-container " >
                <div  className="d-flex justify-content-between" onClick={() => handleShow('reloan')}>
                        <h4 style={show === 'reloan' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.loanTab.reloanTab')}</h4>
                        <img  src={show === 'reloan' ? arrowDown : arrowUp} alt="plus" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'reloan' &&
                    <div >
                        <p className="faq-desc"><ReactMarkdown children={t('faq.loanTab.reloanTabDesc')}  /></p>
                    </div>
                }
            </Col>
            <Col>
                <div className="faqDivider"></div>
                <div className="help-container " >
                <div  className="d-flex justify-content-between" onClick={() => handleShow('rejection')}>
                        <h4 style={show === 'rejection' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.loanTab.rejectionTab')}</h4>
                        <img  src={show === 'rejection' ? arrowDown : arrowUp} alt="plus" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'rejection' &&
                    <div >
                        <p className="faq-desc"><ReactMarkdown children={t('faq.loanTab.rejectionTabDesc')}  /></p>
                    </div>
                }
            </Col>
            <Col>
                <div className="faqDivider"></div>
                <div className="help-container " >
                <div  className="d-flex justify-content-between" onClick={() => handleShow('loan')}>
                        <h4 style={show === 'loan' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.loanTab.confirmationTab')}</h4>
                        <img  src={show === 'loan' ? arrowDown : arrowUp} alt="plus" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'loan' &&
                    <div >
                        <p className="faq-desc"><ReactMarkdown children={t('faq.loanTab.confirmationTabDesc')}  /></p>
                    </div>
                }
            </Col>
            <Col>
                <div className="faqDivider"></div>
                <div className="help-container " >
                <div  className="d-flex justify-content-between" onClick={() => handleShow('bankrupt')}>
                        <h4 style={show === 'bankrupt' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.loanTab.borrowTab')}</h4>
                        <img  src={show === 'bankrupt' ? arrowDown : arrowUp} alt="plus" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'bankrupt' &&
                    <div >
                        <p className="faq-desc"><ReactMarkdown children={t('faq.loanTab.borrowTabDesc')}  /></p>
                    </div>
                }
            </Col>
            <Col>
                <div className="faqDivider"></div>
                <div className="help-container " >
                <div  className="d-flex justify-content-between" onClick={() => handleShow('status')}>
                        <h4 style={show === 'status' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.loanTab.statusTab')}</h4>
                        <img  src={show === 'status' ? arrowDown : arrowUp} alt="plus" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'status' &&
                    <div >
                        <p className="faq-desc"><ReactMarkdown children={t('faq.loanTab.statusTabDesc')}  /></p>
                    </div>
                }
            </Col>
            <Col>
                <div className="faqDivider"></div>
                <div className="help-container " >
                <div  className="d-flex justify-content-between" onClick={() => handleShow('borrow')}>
                        <h4 style={show === 'borrow' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.loanTab.muchTab')}</h4>
                        <img  src={show === 'borrow' ? arrowDown : arrowUp} alt="plus" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'borrow' &&
                    <div >
                        <p className="faq-desc"><ReactMarkdown children={t('faq.loanTab.muchTabDesc')}  /></p>
                    </div>
                }
            </Col>
            <Col>
                <div className="faqDivider"></div>
                <div className="help-container " >
                <div  className="d-flex justify-content-between" onClick={() => handleShow('grantit')}>
                        <h4 style={show === 'grantit' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.loanTab.feeTab')}</h4>
                        <img  src={show === 'grantit' ? arrowDown : arrowUp} alt="rightarrow" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'grantit' &&
                    <div >
                        <p className="faq-desc"><ReactMarkdown children={t('faq.loanTab.feeTabDesx')}  /></p>
                    </div>
                }
            </Col>
            <div className="faqDivider"></div>
        </>
    )
}

export default LoanApplication
