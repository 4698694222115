import React, { forwardRef } from 'react';
import clss from 'classnames';

import styles from './styles.module.scss';

// children: any
// direction: column, row
// spacing: number,
// alignItems: flex-start, center, flex-end, stretch, baseline
// justifyContent: flex-start, center, flex-end, space-between, space-around, space-evenly
// fullWidth: bool
// fullFlex: bool
function Stack(
  {
    children,
    direction,
    alignItems,
    justifyContent,
    spacing = 0,
    fullFlex = false,
    fullWidth = false,
    wrap = false,
  },
  ref
) {
  return (
    <div
      ref={ref}
      className={clss(
        styles.stack,
        styles[`${direction}`],
        styles[`spacing-${spacing}`],
        styles[`alignItems-${alignItems}`],
        styles[`justifyContent-${justifyContent}`],
        {
          [styles.fullFlex]: fullFlex,
          [styles.fullWidth]: fullWidth,
          [styles.wrap]: wrap,
          [styles.nowrap]: !wrap,
        }
      )}
    >
      {children}
    </div>
  );
}

export default forwardRef(Stack);
