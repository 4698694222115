import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';

import './term.css';

function Term() {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col className="term " md={10} sm={12}>
            <h2 className="head">{t('terms.title')}</h2>
            <ReactMarkdown children={t('terms.content')} />
            <p className="termDeleteAccountTips">
              {t('terms.deleteAccount.start')}
              <a
                href="https://forms.gle/ivx9dDk93PCibBsK6"
                target="_blank"
                rel="noreferrer"
              >
                {t('terms.deleteAccount.clickable')}
              </a>
              {t('terms.deleteAccount.end')}
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Term;
