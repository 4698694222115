import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import SignUp from '../signup/SignUp';
import './getstarted.css';
import { useTranslation } from 'react-i18next';

function GetStarted({desc, isGKeeper}) {
    const { t } = useTranslation();
    return (
        <>
            <Container fluid className="get_started">
                <div>
                    <h1 className="start_heading text-center">{t('signUp.getStarted')}</h1>
                </div>
                    <Row className="justify-content-center">
                        <Col lg={7} xl={5} xxl={5} md={8} sm={8}  className="px-lg-5 ">
                            <div className="signup-started">
                                <SignUp desc={desc} isGKeeper={isGKeeper} />
                            </div>
                        </Col>
                    </Row>
            </Container>
        </>
    )
}

export default GetStarted
