import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import './result.css';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../context/LanguageContext';

function Result({goalTitle ,resultImg, goalImg, amount, GoalCompletionAge, HKAverageDiff, userTimeGoal, actualTimeGoal }) {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);

    const yearOrYears = (age) => Math.abs(age) === 1 ? 'year' : 'years';

    const constructText = () => {
        const engText = HKAverageDiff > 0 ? `${HKAverageDiff} ${yearOrYears(HKAverageDiff)} ahead` : `${Math.abs(HKAverageDiff)} ${yearOrYears(HKAverageDiff)} behind`;
        const chiText = HKAverageDiff > 0 ? `早了${HKAverageDiff}年` : `遲了${Math.abs(HKAverageDiff)}年`;
        return language === 'zh' ? chiText : engText;
    };

    return (
        <>
            <Row className="eachRow">
                <Col lg={3} md={3} sm={12}>
                    <div className="d-flex">
                        <img src={resultImg} alt="tick" />
                        <div className="d-flex mx-3">
                            <img src={goalImg} alt="goalImg" />
                            <p className="goalTitle">{goalTitle}</p>
                        </div>
                    </div>
                </Col>
                <Col lg={9} md={9}>
                <Row>
                    <Col lg={2} md={2} sm={12} xs={12}>
                        <p className="ammountNeed">{t('home.calculator.amountNeeded')}</p>
                        <p className="money">${amount.toLocaleString()}</p>
                    </Col>
                    <Col lg={2} md={2} sm={12} xs={12}>
                        <p className="ammountNeed">{t('home.calculator.idealTimeline')}</p>
                        <p className="money">
                            {`${userTimeGoal} ${language === 'zh' ? '年' : yearOrYears(userTimeGoal)}`}
                        </p>
                    </Col>
                    <Col lg={2} md={2} sm={12} xs={12}>
                        <p className="ammountNeed">{t('home.calculator.actualTimeline')}</p>
                        <p className="money">
                            {`${actualTimeGoal} ${language === 'zh' ? '年' : yearOrYears(actualTimeGoal)}`}
                        </p>
                    </Col>
                    <Col lg={2} md={2} sm={12} xs={12}>
                        <p className="ammountNeed">{t('home.calculator.goalCompletion')}</p>
                        <p className="money">{GoalCompletionAge}{language === 'zh' ? '歲' : ''}</p>
                    </Col>
                    <Col lg={3} md={3} sm={12} xs={12}>
                        <p className="ammountNeed">{t('home.calculator.hkAverage')}</p>
                        <p className="money">
                            {
                                constructText()
                            }
                        </p>
                    </Col>
                </Row>
            </Col>
            </Row>
        </>
    )
}
export default Result