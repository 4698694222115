import { object, string } from 'yup';

import { phoneNumberFormatRegexp } from 'utils/Validation';
import { spaceRemover } from 'utils/Transforms';

export const PhoneFormSchema = object().shape({
  phoneNumber: string()
    .transform(spaceRemover)
    .required('common.validations.requiredField')
    .length(8, 'common.validations.invalidPhoneNumberLength')
    .matches(
      phoneNumberFormatRegexp,
      'common.validations.invalidPhoneNumberFormat'
    ),
});
