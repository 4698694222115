import { useTranslation } from 'react-i18next';

import Typography from 'components/atoms/typography';
import Button from 'components/atoms/button';
import Stack from 'components/atoms/stack';

import appleIcon from 'asset/icons/apple.svg';
import googlePlayIcon from 'asset/icons/google_play.svg';

import styles from './styles.module.scss';

function ExistingUser() {
  const { t } = useTranslation();

  const onOpenApp = () => {
    window.open(window.AF_SMART_SCRIPT_RESULT.clickURL);
  };

  return (
    <div className={styles.background}>
      <div className={styles.card}>
        <Typography size="xh1" weight="medium" color="heading">
          {t('application.existingUser.title')}
        </Typography>
        <Typography
          size="title1"
          weight="normal"
          color="inactive"
          wrap={true}
          align="center"
        >
          {t('application.existingUser.details')}
        </Typography>
        <Button.Primary
          fullSize="lg"
          onClick={onOpenApp}
          className={styles.button}
        >
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Typography size="h4" weight="medium" color="white" wrap={false}>
              {t('application.existingUser.openApp')}
            </Typography>
            <img src={appleIcon} alt="open-app-apple" />
            <img src={googlePlayIcon} alt="open-app-google-play" />
          </Stack>
        </Button.Primary>
      </div>
    </div>
  );
}

export default ExistingUser;
