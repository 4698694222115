import React, { useState, useRef } from 'react';
import Header from '../navbar/Header';
import success from '../images/success.svg';
import copy from '../images/copy.svg';
import copied from '../images/copied.svg';
import arrow from '../images/arrow.svg';
import downarrow from '../images/downarrow.svg';
import rightarrow from '../images/rightarrow.svg';
import './thanku.css';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../footer/Footer';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useNavigate, useLocation } from 'react-router-dom';

function Thanku() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [show, setShow] = useState(false);
    const [isCopy, setIsCopy] = useState(false);
    const target = useRef(null);
    const [showTerm, setShowTerm] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(state.share_link);
        setIsCopy(true);
        setShow(true);
        setTimeout(function () {
            setIsCopy(false);
            setShow(false);
        }, 3000);
    }

    return (
        <>
            <Header />
            <div className="thankubg"></div>
            <div className="waitingListTick d-flex justify-content-center">
                <img src={success} className="success" alt="success" />
            </div>
            <Container>
                <Row className="justify-content-center mt-2">
                    <Col md={5} sm={12}>
                        <h1 className="thankuHeading text-center">{t('thanku.thankuHeading')}</h1>
                        <h3 className="descHeading text-center">{t('thanku.descHeading')} </h3>
                        <div className="thankuDetail">
                            <p><ReactMarkdown children={t('thanku.desc')} /></p>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md={4} sm={12}>
                        <div className="d-flex clipinput">
                            <input disabled type="text" className="clipboard" value={state.share_link} />
                            <img src={show ? copied : copy} className="copy" onClick={handleCopy} alt="copy" />
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md={6} sm={12}>
                        <div className="t-condition d-flex justify-content-center" onClick={() => setShowTerm(!showTerm)}>
                            <p className="text-center thankuTerm">{t('thanku.Term')}</p>
                            <img src={showTerm ? downarrow : rightarrow} alt="arrow" className="mx-2" />
                        </div>
                        {showTerm && 
                         <ReactMarkdown className="termC" children={t('thanku.termsAndCondition')}  />
                        }
                        <div className="thankuwaitingList mx-auto" onClick={() => navigate(-1)}>
                            {t('thanku.backbtn')}
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="footerspace">
                <Footer />
            </div>
            {isCopy &&
                <div className="linked" ref={target}>
                    <img src={arrow} alt="arrow" className="my-auto" />
                    <p className="link-copied my-auto">{t('thanku.copy')}</p>
                </div>
            }
        </>
    );
}

export default Thanku;
