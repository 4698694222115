import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import arrowUp from '../../components/gkeeperfaq/images/arrowSecondUp.svg';
import arrowDown from '../../components/gkeeperfaq/images/arrowSecondDown.svg';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';


function InterestCalculation() {
    const [show, setShow] = useState('');
    const { t } = useTranslation();

    const handleShow = (action) => {
        if (action === 'rule' ) {
            setShow((prev) => prev === action ? '' : action);
        }
    }

    return (
        <>
            <Col style={{ 'marginTop': '38px' }}>
                <div className="faqDivider"></div>
                <div className="help-container " >
                <div  className="d-flex justify-content-between" onClick={() => handleShow('rule')}>
                        <h4 style={show === 'rule' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.calculationTab.rule')}</h4>
                        <img  src={show === 'rule' ? arrowDown: arrowUp} alt="rightarrow" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'rule' &&
                    <div>
                        <p className="faq-desc"><ReactMarkdown children={t('faq.calculationTab.ruleDesc')} /></p>
                    </div>
                }
            </Col>
            <div className="faqDivider"></div>
        </>
    )
}

export default InterestCalculation
