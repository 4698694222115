import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18next';
import { LanguageContextProvider } from './context/LanguageContext';
import Spiner from './components/spinner/Spiner';
import { polyfill } from 'smoothscroll-polyfill';
// import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

import { appsflyerPolyfill } from './appsflyerPolyfill';

polyfill();
ReactDOM.hydrate(
  <React.StrictMode>
    <Suspense fallback={<Spiner />}>
      <LanguageContextProvider>
        <App />
      </LanguageContextProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID
};
TagManager.initialize(tagManagerArgs);

// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

appsflyerPolyfill();

// function sendToAnalytics({ id, name, value }) {
//   ReactGA.event({
//     category: 'Web Vitals',
//     action: name,
//     value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
//     label: id, // id unique to current page load
//     nonInteraction: true, // avoids affecting bounce rate
//   });
// } 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
