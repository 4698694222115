import React, {useState, useEffect, useRef, useContext} from 'react';
import DatePicker from "react-datepicker";
import "./goal.css";
import { Accordion } from 'react-bootstrap';
import { NumericFormat as NumberFormat } from 'react-number-format';
import "react-datepicker/dist/react-datepicker.css";
import { enGB, zhHK } from 'date-fns/locale';
import {LanguageContext} from '../../context/LanguageContext';
import { useTranslation } from 'react-i18next';
import { addYears } from 'date-fns';

function Goal({ goal, Img, amount, timeLine, amountValue, timelineValue, handleFormData, setBothInput }) {
    const [show, setShow] = useState(false);
    const amountRef = useRef();
    const timelineRef = useRef();
    const accordianRef = useRef();

    const { t } = useTranslation();  
    const { language } = useContext(LanguageContext);

    const today = new Date();

    useEffect(() => {
        if(amountValue && timelineValue) {
            setBothInput(true);
        } else {
            setBothInput(false);
        }
    },[amountValue, timelineValue, setBothInput]);

    const handleClick = () => {
        setShow(!show);
    }

    useEffect(() => {
        if(show) {
            accordianRef.current.style.border = ' 2px solid #4BA3FF';
        }else {
            accordianRef.current.style.border = ' 1px solid #99B3C6';
        }    
    }, [show]);

    return (
        <Accordion onClick={handleClick} >
            <Accordion.Item eventKey="1" ref={accordianRef}>
                <Accordion.Header>
                    <div className="goals">
                        <img src={Img} alt="property" />
                        <p className="downPayment">{goal}</p>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="amountNeed">
                        <div>
                            <label className="amount">{t('home.calculator.amountNeeded')}</label>
                            <div className="amountInput">
                                <label className="dollar2">$</label>
                                <NumberFormat
                                    ref={amountRef}
                                    value={amountValue ? amountValue : ''}
                                    className="step2Input inputWidth"
                                    thousandSeparator={true}
                                    onChange={handleFormData(amount)}
                                    name={amount}
                                />
                            </div>
                        </div>
                        <div className="mt-2 mb-2">
                            <label className="amount">{t('home.calculator.timeline')}</label> 
                            <DatePicker
                                locale={language === 'zh' ? zhHK : enGB}
                                ref={timelineRef}
                                className="step2Input inputWidth"
                                selected={timelineValue ? new Date(timelineValue) : timelineValue}
                                onChange={handleFormData(timeLine)}
                                dateFormat="MM/yyyy"
                                minDate={today}
                                maxDate={addYears(today, 50)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="MM/YYYY"
                            />                           
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default Goal

