import { number, object, string } from 'yup';

import i18n from 'i18next';
import { thousandSeparatorReverse } from 'utils/Transforms';

export const getAge = () => [
  { value: 'under20', label: i18n.t('eligibility.age.under20') },
  { value: '20-25', label: i18n.t('eligibility.age.between20and25') },
  { value: '26-34', label: i18n.t('eligibility.age.between26and34') },
  { value: '35-40', label: i18n.t('eligibility.age.between35and40') },
  { value: '41-60', label: i18n.t('eligibility.age.between41and60') },
  { value: 'over60', label: i18n.t('eligibility.age.over60') },
];

export const getHKIDSymbols = () => [
  { value: 'az', label: i18n.t('eligibility.hkidSymbols.az') },
  { value: 'ao', label: i18n.t('eligibility.hkidSymbols.ao') },
  { value: 'cx', label: i18n.t('eligibility.hkidSymbols.cx') },
  { value: 'ax', label: i18n.t('eligibility.hkidSymbols.ax') },
  { value: 'co', label: i18n.t('eligibility.hkidSymbols.co') },
  { value: 'other', label: i18n.t('eligibility.hkidSymbols.other') },
];

export const getMaritalStatus = () => [
  { value: 'single', label: i18n.t('eligibility.maritalStatus.single') },
  { value: 'married', label: i18n.t('eligibility.maritalStatus.married') },
  { value: 'divorced', label: i18n.t('eligibility.maritalStatus.divorced') },
  { value: 'other', label: i18n.t('eligibility.maritalStatus.other') },
];

export const getCreditCardsNum = () => [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: 'fiveOrMore', label: i18n.t('eligibility.fiveOrMore') },
];

export const getHousingPropertyType = () => [
  {
    value: 'public',
    label: i18n.t('eligibility.housingPropertyTypes.public'),
  },
  {
    value: 'private',
    label: i18n.t('eligibility.housingPropertyTypes.private'),
  },
  { value: 'hos', label: i18n.t('eligibility.housingPropertyTypes.hos') },
  { value: 'other', label: i18n.t('eligibility.housingPropertyTypes.other') },
];

export const getMonthlyRentalAmount = () => [
  {
    value: 'na',
    label: i18n.t('eligibility.monthlyRentalAmount.na'),
  },
  {
    value: 'oneToTwoThousands',
    label: i18n.t('eligibility.monthlyRentalAmount.oneToTwoThousands'),
  },
  {
    value: 'twoToFiveThousands',
    label: i18n.t('eligibility.monthlyRentalAmount.twoToFiveThousands'),
  },
  {
    value: 'fiveToTenThousands',
    label: i18n.t('eligibility.monthlyRentalAmount.fiveToTenThousands'),
  },
  {
    value: 'moreThanTenThousands',
    label: i18n.t('eligibility.monthlyRentalAmount.moreThanTenThousands'),
  },
];

export const getYearsInCompany = () => [
  {
    value: 'unemployed',
    label: i18n.t('eligibility.yearsInCompany.unemployed'),
  },
  {
    value: 'underOneYear',
    label: i18n.t('eligibility.yearsInCompany.underOneYear'),
  },
  {
    value: 'oneToTwoYears',
    label: i18n.t('eligibility.yearsInCompany.oneToTwoYears'),
  },
  {
    value: 'threeToFourYears',
    label: i18n.t('eligibility.yearsInCompany.threeToFourYears'),
  },
  {
    value: 'fiveToSevenYears',
    label: i18n.t('eligibility.yearsInCompany.fiveToSevenYears'),
  },
  {
    value: 'overSevenYears',
    label: i18n.t('eligibility.yearsInCompany.overSevenYears'),
  },
];

export const getIncomePaymentMethods = () => [
  {
    value: 'autopay',
    label: i18n.t('eligibility.incomePaymentMethods.autoPay'),
  },
  {
    value: 'transfer',
    label: i18n.t('eligibility.incomePaymentMethods.bankTransfer'),
  },
  {
    value: 'cashCheque',
    label: i18n.t('eligibility.incomePaymentMethods.cashCheque'),
  },
  { value: 'cash', label: i18n.t('eligibility.incomePaymentMethods.cash') },
  {
    value: 'cheque',
    label: i18n.t('eligibility.incomePaymentMethods.cheque'),
  },
  { value: 'other', label: i18n.t('eligibility.incomePaymentMethods.other') },
];

export const getAccomodationTypes = () => [
  {
    value: 'rental',
    label: i18n.t('eligibility.accommodationType.rental'),
  },
  {
    value: 'owned',
    label: i18n.t('eligibility.accommodationType.owned'),
  },
];

export const getFormFields = () => ({
  age: getAge(),
  hkidSymbol: getHKIDSymbols(),
  maritalStatus: getMaritalStatus(),
  creditCardsNum: getCreditCardsNum(),
  housingType: getHousingPropertyType(),
  accomodationType: getAccomodationTypes(),
  monthlyRent: getMonthlyRentalAmount(),
  currentPositionYears: getYearsInCompany(),
  incomePaymentMethods: getIncomePaymentMethods(),
});

export const EligibilitySchema = object().shape({
  age: string()
    .required()
    .oneOf(['under20', '20-25', '26-34', '35-40', '41-60', 'over60']),
  hkidSymbol: string()
    .required()
    .oneOf(['az', 'ao', 'cx', 'ax', 'co', 'other']),
  maritalStatus: string()
    .required()
    .oneOf(['single', 'married', 'divorced', 'other']),
  creditCardsNum: string()
    .required()
    .oneOf(['0', '1', '2', '3', '4', 'fiveOrMore']),
  averageInstallmentPerMonth: number()
    .transform(thousandSeparatorReverse)
    .required(),
  housingType: string().required().oneOf(['public', 'private', 'hos', 'other']),
  accomodationType: string().when('housingType', ([housingType], schema) => {
    return housingType ? schema.required().oneOf(['rental', 'owned']) : schema;
  }),
  monthlyRent: string().when(
    'accomodationType',
    ([accomodationType], schema) => {
      return accomodationType === 'rental' ? schema.required() : schema;
    }
  ),
  currentPositionYears: string()
    .required()
    .oneOf([
      'unemployed',
      'underOneYear',
      'oneToTwoYears',
      'threeToFourYears',
      'fiveToSevenYears',
      'overSevenYears',
    ]),
  monthlySalary: number()
    .positive()
    .transform(thousandSeparatorReverse)
    .when('currentPositionYears', ([currentPositionYears], schema) => {
      return currentPositionYears && currentPositionYears !== 'unemployed'
        ? schema.required()
        : schema;
    }),
  incomePaymentMethod: string().when(
    'currentPositionYears',
    ([currentPositionYears], schema) => {
      return currentPositionYears && currentPositionYears !== 'unemployed'
        ? schema
            .required()
            .oneOf([
              'autopay',
              'transfer',
              'cashCheque',
              'cash',
              'cheque',
              'other',
            ])
        : schema;
    }
  ),
});
