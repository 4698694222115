
import { useContext } from 'react';
import Result from '../../result/Result';
import './step4.css';
import tick from './images/tick.svg';
import cross from './images/cross.svg';
// import joined from './images/joined.svg';
import property from '../step2/images/property.svg';
import car from '../step2/images/car.svg';
import married from '../step2/images/married.svg';
import travel from '../step2/images/travel.svg';
import retirement from '../step2/images/retirement.svg';
import rightArrow from '../step2/images/rightArrow.svg';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../../context/LanguageContext';

function Step4({ goal, recordId }) {
  // const [listJoined, setListJoined] = useState(false);
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);

  // const joinWaitingList = async () => {
  //   await fetch(`${process.env.REACT_APP_CMS_APP_URL}/api/userinfos/${recordId}`, {
  //     method: 'PUT',
  //     mode: 'cors',
  //     cache: 'no-cache',
  //     credentials: 'same-origin',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     referrerPolicy: 'no-referrer',
  //     body: JSON.stringify({ data: { "waitinglist": true } })
  //   }).then(response => response.json())
  //     .then(data => {
  //       setListJoined(true);
  //     }).catch((err) => {
  //       console.log(err);
  //     });
  // };

  const redirectLink = (text) => {
    if (text === 'landing') {
      window.open(window.AF_SMART_SCRIPT_RESULT.clickURL);
    }
  }

  const { 
    propertyHKAverageDiff, carHKAverageDiff, marriageHKAverageDiff, travelHKAverageDiff, retirementHKAverageDiff
  } = goal;

  let propertyGoalMet = goal.propertyAmount ? goal.actualTimePropertyGoal <= goal.userTimePropertyGoal : -1;
  let carGoalMet = goal.carAmount ? goal.actualTimeCarGoal <= goal.userTimeCarGoal : -1;
  let marriageGoalMet = goal.marriageAmount ? goal.actualTimeMarriageGoal <= goal.userTimeMarriageGoal : -1;
  let travelGoalMet = goal.travelAmount ? goal.actualTimeTravelGoal <= goal.userTimeTravelGoal : -1;
  let retirementGoalMet = goal.retirementAmount ? goal.actualTimeRetirementGoal <= goal.userTimeRetirementGoal : -1;

  const showHeadingDependingGoalsMet = () => {
    let countTrue = 0, countFalse = 0;
    if (propertyGoalMet !== -1) {
      if (propertyGoalMet) countTrue++;
      else countFalse++;
    }
    if (carGoalMet !== -1) {
      if (carGoalMet) countTrue++;
      else countFalse++;
    }
    if (marriageGoalMet !== -1) {
      if (marriageGoalMet) countTrue++;
      else countFalse++;
    }
    if (travelGoalMet !== -1) {
      if (travelGoalMet) countTrue++;
      else countFalse++;
    }
    if (retirementGoalMet !== -1) {
      if (retirementGoalMet) countTrue++;
      else countFalse++;
    }

    if (countTrue > 0 && countFalse > 0) {
      const engOpt = ['property purchase', 'car purchase', 'marriage', 'travelling', 'early retirement']
      const chiOpt = ['置業', '買車', '結婚', '環遊世界', '退休'];

      const goalsMet_eng = [], goalsMet_chi = [];
      const goalsUnMet_eng = [], goalsUnMet_chi = [];
      if (propertyGoalMet !== -1) {
        propertyGoalMet ? goalsMet_eng.push(engOpt[0]) && goalsMet_chi.push(chiOpt[0]) 
        : goalsUnMet_eng.push(engOpt[0]) && goalsUnMet_chi.push(chiOpt[0]);
      }
      if (carGoalMet !== -1) {
        carGoalMet ? goalsMet_eng.push(engOpt[1]) && goalsMet_chi.push(chiOpt[1]) 
        : goalsUnMet_eng.push(engOpt[1]) && goalsUnMet_chi.push(chiOpt[1]);
      }
      if (marriageGoalMet !== -1) {
        marriageGoalMet ? goalsMet_eng.push(engOpt[2]) && goalsMet_chi.push(chiOpt[2]) 
        : goalsUnMet_eng.push(engOpt[2]) && goalsUnMet_chi.push(chiOpt[2]);
      }
      if (travelGoalMet !== -1) {
        travelGoalMet ? goalsMet_eng.push(engOpt[3]) && goalsMet_chi.push(chiOpt[3]) 
        : goalsUnMet_eng.push(engOpt[3]) && goalsUnMet_chi.push(chiOpt[3]);
      }
      if (retirementGoalMet !== -1) {
        retirementGoalMet ? goalsMet_eng.push(engOpt[4]) && goalsMet_chi.push(chiOpt[4]) 
        : goalsUnMet_eng.push(engOpt[4]) && goalsUnMet_chi.push(chiOpt[4]);
      }
      
      const engText = `Congratulations! You are well on your way to achieve ${goalsMet_eng.join(', ')} life goals at your desired timeline!
      However you need to catch up in order to acheive ${goalsUnMet_eng.join(', ')} life goals at your desired timeline! 
      Further help from innovative tools will navigate you straight to the finish line.`;

      const chiText = `恭喜，你正按照自己的步伐走向通往 ${goalsMet_chi.join(', ')} 目標的道路！ 但你還需努力更快達成 
      ${goalsUnMet_chi.join(', ')} 的人生目標啊！理財工具或許能助你更進一步達成目標。`;

      return language === 'zh' ? chiText : engText;

    } else if (countTrue > 0 && countFalse === 0) {
      if ((!propertyHKAverageDiff || propertyHKAverageDiff >= 10) && (!carHKAverageDiff || carHKAverageDiff >= 10) 
        && (!marriageHKAverageDiff || marriageHKAverageDiff >= 10) && (!travelHKAverageDiff || travelHKAverageDiff >= 10)
        && (!retirementHKAverageDiff || retirementHKAverageDiff >= 10)) {
          return t('home.calculator.resultHeading2')
      } else {
        return t('home.calculator.resultHeading1');
      }
    } else {
      return t('home.calculator.resultHeading3');
    }
  };

  return (
    <>
      <Col lg={11}>
        <h5 className="lifeGoal">
          {
            showHeadingDependingGoalsMet()
          }
        </h5>
      </Col>
      {goal.propertyAmount &&
        <Result
          resultImg={propertyGoalMet ? tick : cross}
          goalImg={property}
          amount={goal.propertyAmount}
          GoalCompletionAge={goal.propertyGoalCompletionAge}
          HKAverageDiff={propertyHKAverageDiff}
          userTimeGoal={goal.userTimePropertyGoal}
          actualTimeGoal={goal.actualTimePropertyGoal}
          goalTitle={t("home.calculator.propertyPayment")}
        />
      }
      {goal.carAmount &&
        <Result
          resultImg={carGoalMet ? tick : cross}
          goalImg={car}
          amount={goal.carAmount}
          GoalCompletionAge={goal.carGoalCompletionAge}
          HKAverageDiff={carHKAverageDiff}
          userTimeGoal={goal.userTimeCarGoal}
          actualTimeGoal={goal.actualTimeCarGoal}
          goalTitle={t("home.calculator.buyCar")}
        />
      }
      {goal.marriageAmount &&
        <Result
          resultImg={marriageGoalMet ? tick : cross}
          goalImg={married}
          amount={goal.marriageAmount}
          GoalCompletionAge={goal.marriageCompletionAge}
          HKAverageDiff={marriageHKAverageDiff}
          userTimeGoal={goal.userTimeMarriageGoal}
          actualTimeGoal={goal.actualTimeMarriageGoal}
          goalTitle={t("home.calculator.gettingMarried")}
        />
      }
      {goal.travelAmount &&
        <Result
          resultImg={travelGoalMet ? tick : cross}
          goalImg={travel}
          amount={goal.travelAmount}
          GoalCompletionAge={goal.travelGoalCompletionAge}
          HKAverageDiff={travelHKAverageDiff}
          userTimeGoal={goal.userTimeTravelGoal}
          actualTimeGoal={goal.actualTimeTravelGoal}
          goalTitle={t("home.calculator.travelWorld")}
        />
      }
      {goal.retirementAmount &&
        <Result
          resultImg={retirementGoalMet ? tick : cross}
          goalImg={retirement}
          amount={goal.retirementAmount}
          GoalCompletionAge={goal.retirementGoalCompletionAge}
          HKAverageDiff={retirementHKAverageDiff}
          userTimeGoal={goal.userTimeRetirementGoal}
          actualTimeGoal={goal.actualTimeRetirementGoal}
          goalTitle={t("home.calculator.earlyRetirement")}
        />
      }

      <div className="line1"></div>
      <h5 className="lifeGoal">{t('home.calculator.grantitTool')}</h5>
      <Col lg={10} sm={12}>
      <p className="step4Desc">{t('home.calculator.grantitBenefit')}</p>
      </Col>
        <div className="waitingList" onClick={() => redirectLink('landing')}>
          <h5 className="rankingBtn">{t('home.calculator.joinWaitingList')}</h5>
          <img width="18px" src={rightArrow} alt="rightArrow" className="rankingArrow" />
        </div>
        {/* :
        <div className="waitingListJoined">
          <img width="16px" src={joined} alt="joined" />
          <h5 className="textJoined">{t('home.calculator.joinedWaitingList')}</h5>
        </div>
      } */}
    </>
  )
}
export default Step4