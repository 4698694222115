import { useState } from 'react';
import { Outlet, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import Stack from 'components/atoms/stack';
import StepLayout from 'components/layout/step';
import Alert from 'components/atoms/alert';
import Spacer from 'components/atoms/spacer';

import safeIcon from 'asset/icons/safe.webp';

function Layout() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const { t } = useTranslation();
  const location = useLocation();

  const alertType = location?.pathname?.includes('otp') ? 'hide' : 'primary';

  return (
    <StepLayout activeIndex={1}>
      <Stack direction="column" alignItems="center" spacing={0}>
        <Stack fullWidth direction="column" alignItems="center" spacing={5}>
          <Outlet context={[phoneNumber, setPhoneNumber]} />
        </Stack>
        <Spacer xlSize={20} lgSize={5} />
        <Alert
          type={alertType}
          className={`phone-verication-alert-${alertType}`}
          heading={t('application.signUp.alertSafe.heading')}
          body={t('application.signUp.alertSafe.body')}
          icon={safeIcon}
        />
      </Stack>
    </StepLayout>
  );
}

export default Layout;
