/**
 * from onelink-script.js code
 */
const APPSFLYER_SCRIPT_URL =
  'https://onelinksmartscript.appsflyer.com/onelink-smart-script-latest.js';

/**
 * Because the PROD environment is automatically loaded from the google tag script onelink-script.js
 * So we need to compensate for the lack of onelink-script.js in our test environment.
 */
export const appsflyerPolyfill = () => {
  if (process.env.REACT_APP_ENV === 'TEST') {
    const el = document.createElement('script');
    el.src = APPSFLYER_SCRIPT_URL;

    el.addEventListener('load', () => {
      // mock PROD code
      const oneLinkURL = 'https://grantit.onelink.me/tiWR';
      const mediaSource = { keys: ['utm_source'], defaultValue: 'website' };
      const campaign = { keys: ['utm_campaign'], defaultValue: 'website' };
      const channel = { keys: ['utm_medium'], defaultValue: 'website' };
      const custom_ss_ui = { paramKey: 'af_ss_ui', defaultValue: 'true' };
      const custom_ss_gtm_ui = {
        paramKey: 'af_ss_gtm_ui',
        defaultValue: 'true',
      };

      window.AF_SMART_SCRIPT_RESULT = window.AF_SMART_SCRIPT.generateOneLinkURL(
        {
          oneLinkURL: oneLinkURL,
          afParameters: {
            mediaSource: mediaSource,
            campaign: campaign,
            channel: channel,
            afCustom: [custom_ss_ui, custom_ss_gtm_ui],
          },
        }
      );
    });

    document.body.append(el);
  }
};

export default appsflyerPolyfill;
