import React, { forwardRef } from 'react';
import clss from 'classnames';
import { NumericFormat as NumberFormat } from 'react-number-format';
import Stack from 'components/atoms/stack';
import Typography from 'components/atoms/typography';
import Spacer from 'components/atoms/spacer';

import styles from './styles.module.scss';

// value: string | number
// onChange: (value, option) => void
// title: string
// sequence: string
// error: string
function NumberInput(
  {
    value,
    onChange,
    title,
    error = undefined,
    sequence = undefined,
    prefix = '$',
    placeholder = '',
    gappable = true,
    ...props
  },
  ref
) {
  return (
    <Stack direction="column" spacing={2} fullWidth>
      {title && (
        <Stack
          fullWidth={true}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography size="body1" weight="medium" color="title" wrap>
            {title}
          </Typography>
          {!!sequence && (
            <Typography size="body2" weight="normal" color="tip">
              {sequence}
            </Typography>
          )}
        </Stack>
      )}
      {/* tag list */}
      {!!error && (
        <Typography size="title2" color="error" wrap>
          {error}
        </Typography>
      )}
      {gappable && <Spacer xlSize={2} lgSize={0} />}
      <div className={styles.inputContainer}>
        {!!prefix && (
          <Typography size="h4" color="title" className={styles.inputPrefix}>
            {prefix}
          </Typography>
        )}
        <NumberFormat
          {...props}
          getInputRef={ref}
          value={value}
          placeholder={placeholder}
          className={clss(styles.input, {
            [styles.prefix]: !!prefix,
            [styles.error]: !!error,
          })}
          thousandSeparator={true}
          onValueChange={(value) => onChange(value.floatValue)}
        />
      </div>
    </Stack>
  );
}

export default forwardRef(NumberInput);
