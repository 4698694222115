import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import Stack from 'components/atoms/stack';
import Tag from 'components/atoms/tag';
import Typography from 'components/atoms/typography';
import Spacer from 'components/atoms/spacer';
import Button from 'components/atoms/button';

// return [[1,2,3], ...]
const split3 = (array = [], targetTotal = 3) => {
  return array.reduce(
    (prev, item, currentIndex) => {
      let last = prev[prev.length - 1];
      // new group
      if (last.length >= targetTotal) {
        last = [];
        prev.push(last);
      }
      // add item
      last.push(item);
      // last check and fill empty
      if (currentIndex === array.length - 1 && last.length < targetTotal) {
        last.push(
          ...Array.from({ length: targetTotal - last.length }, (v) => null)
        );
      }
      return prev;
    },
    [[]]
  );
};

// options: {value, label}[]
// value: string | number
// onChange: (value, option) => void
// title: string
// sequence: string
// error: string
// tip: string
// onTip: click tip callback
function TagRadio(
  {
    options,
    value,
    onChange,
    title,
    tip = undefined,
    onTip = () => {},
    error = undefined,
    sequence = undefined,
    gappable = true,
  },
  ref
) {
  const containerRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      focus: () => {
        containerRef.current.scrollIntoViewIfNeeded();
      },
    }),
    []
  );

  return (
    <Stack ref={containerRef} direction="column" spacing={2} fullWidth>
      {title && (
        <Stack
          fullWidth={true}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography size="body1" weight="medium" color="title" wrap>
            {title}
          </Typography>
          {!!sequence && (
            <Typography size="body2" weight="normal" color="tip">
              {sequence}
            </Typography>
          )}
        </Stack>
      )}
      <Stack
        fullWidth
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {!!error && (
          <Typography size="title2" color="error">
            {error}
          </Typography>
        )}
        {!!tip && (
          <Button.Ghost onClick={onTip}>
            <Typography size="body3" color="link">
              {tip}
            </Typography>
          </Button.Ghost>
        )}
      </Stack>
      {/* tag list */}
      {gappable && <Spacer xlSize={2} lgSize={0} />}
      {options.length <= 4 ? (
        <Stack fullWidth={true} direction="row" spacing={2}>
          {options.map((option, index) => (
            <Stack key={index} fullFlex>
              <Tag
                active={option.value === value}
                onClick={() => onChange(option.value, option)}
              >
                {option.label}
              </Tag>
            </Stack>
          ))}
        </Stack>
      ) : (
        <Stack fullWidth={true} direction="column" spacing={2}>
          {split3(options).map((items3, index) => (
            <Stack key={index} fullWidth={true} direction="row" spacing={2}>
              {items3.map((option, index) =>
                option === null ? (
                  <Stack fullFlex key={index} />
                ) : (
                  <Stack fullFlex key={option.value}>
                    <Tag
                      active={option.value === value}
                      onClick={() => onChange(option.value, option)}
                    >
                      {option.label}
                    </Tag>
                  </Stack>
                )
              )}
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
}

export default forwardRef(TagRadio);
