import React, { forwardRef } from 'react';
import RVerificationInput from 'react-verification-input';
import Stack from 'components/atoms/stack';
import Typography from 'components/atoms/typography';

import styles from './styles.module.scss';

// value: string | number
// onChange: (value, option) => void
// title: string
// sequence: string
// error: string
function VerificationInput(
  { error = undefined, placeholder = ' ', ...props },
  ref
) {
  return (
    <Stack
      direction="column"
      spacing={1}
      fullWidth
      alignItems="center"
      justifyContent="center"
    >
      {/* tag list */}
      {!!error && (
        <Typography size="title2" color="error" wrap={true}>
          {error}
        </Typography>
      )}
      <RVerificationInput
        {...props}
        ref={ref}
        placeholder={placeholder}
        classNames={{
          container: styles.container,
          character: styles.character,
          characterSelected: styles.characterSelected,
          characterFilled: styles.characterFilled,
        }}
      />
    </Stack>
  );
}

export default forwardRef(VerificationInput);
