import React, { forwardRef } from 'react';
import clss from 'classnames';
import { PatternFormat } from 'react-number-format';
import Stack from 'components/atoms/stack';
import Typography from 'components/atoms/typography';

import styles from './styles.module.scss';

// value: string | number
// onChange: (value, option) => void
// title: string
// sequence: string
// error: string
function PhoneInput(
  {
    value,
    onChange,
    error = undefined,
    prefix = '+852',
    placeholder = '',
    ...props
  },
  ref
) {
  return (
    <Stack
      direction="column"
      spacing={1}
      fullWidth
      alignItems="center"
      justifyContent="center"
    >
      {/* tag list */}
      {!!error && (
        <Typography
          size="title2"
          color="error"
          wrap={true}
          className={styles.inputError}
        >
          {error}
        </Typography>
      )}
      <div className={styles.inputContainer}>
        {!!prefix && (
          <Typography size="h4" color="title" className={styles.inputPrefix}>
            {prefix}
          </Typography>
        )}
        <PatternFormat
          {...props}
          getInputRef={ref}
          value={value}
          placeholder={placeholder}
          className={clss(styles.input, {
            [styles.prefix]: !!prefix,
            [styles.error]: !!error,
          })}
          onChange={onChange}
        />
      </div>
    </Stack>
  );
}

export default forwardRef(PhoneInput);
