import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import clss from 'classnames';

import Faq from 'pages/faq/Faq';

import Button from 'components/atoms/button';
import Typography from 'components/atoms/typography';
import Spacer from 'components/atoms/spacer';
import Stack from 'components/atoms/stack';
import useAxios from 'hooks/useAxios';

import { decrypt } from 'utils/Encrypted';
import { generateDeepLinkWithPhoneNumber } from 'utils/Deeplink';

import downloadAppImg from './images/download_app.png';
import docUploadImg from './images/doc_upload.png';
import signContractImg from './images/sign_contract.png';
import clockImg from './images/clock.png';
import markIcon from './images/mark.svg';
import appleIcon from 'components/navbar/images/apple.svg';
import googlePlayIcon from 'components/navbar/images/google_play.svg';

import cardEngImg from './images/card_eng.png';
import cardZhImg from './images/card_zh.png';
import perkEnImg from './images/perk_eng.png';
import perkZhImg from './images/perk_zh.png';

import styles from './styles.module.scss';

const FeatItem = ({ alt, icon, title = '', detail = '' }) => {
  return (
    <div className={styles.cell}>
      <img
        src={icon}
        alt={`cell-item-icon-${alt ?? 'default'}`}
        className={styles.icon}
      />
      <div className={styles.cellTextContainer}>
        <Typography size="h4" weight="medium" color="body">
          {title}
        </Typography>
        <Typography size="body2" weight="normal" color="inactive" wrap={true}>
          {detail}
        </Typography>
      </div>
    </div>
  );
};

const FeatList = [
  {
    icon: downloadAppImg,
    alt: 'downloadApp',
    title: 'application.resultPage.step1Title',
    detail: 'application.resultPage.step1Description',
  },
  {
    icon: docUploadImg,
    alt: 'docUpload',
    title: 'application.resultPage.step2Title',
    detail: 'application.resultPage.step2Description',
  },
  {
    icon: signContractImg,
    alt: 'signContract',
    title: 'application.resultPage.step3Title',
    detail: 'application.resultPage.step3Description',
  },
  {
    icon: clockImg,
    alt: 'clockImg',
    title: 'application.resultPage.step4Title',
    detail: 'application.resultPage.step4Description',
  },
];

const ProgressNode = ({ mark = true, step = 1, title = '' }) => (
  <Stack
    direction="column"
    alignItems="center"
    justifyContent="center"
    spacing={2}
  >
    {mark ? (
      <img width={30} src={markIcon} alt="progress-node-mark-icon" />
    ) : (
      <Typography
        size="h4"
        weight="medium"
        align="center"
        className={styles.nodeNum}
      >
        {step}
      </Typography>
    )}
    <Typography
      size="body1"
      color={!mark ? 'title' : 'inactive'}
      weight="medium"
      align="center"
      wrap={true}
    >
      {title}
    </Typography>
  </Stack>
);

const ProgressSteps = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.progressSteps}>
      <div className={styles.line} />
      <ProgressNode
        mark={true}
        step={1}
        title={t('application.resultPage.progress.step1')}
      />
      <ProgressNode
        mark={true}
        step={2}
        title={t('application.resultPage.progress.step2')}
      />
      <ProgressNode
        mark={false}
        step={3}
        title={t('application.resultPage.progress.step3')}
      />
      <ProgressNode
        mark={false}
        step={4}
        title={t('application.resultPage.progress.step4')}
      />
    </div>
  );
};

// type Params = {
//   result: "very_good" | "maybe" | "bad";
// }

const EligibilityResult = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // get result by query
  const [searchParams] = useSearchParams();
  const result = searchParams.get('result');

  // calc workday by server
  const now = dayjs().format('YYYY-MM-DD');
  const { data: nonWorkingDays } = useAxios({
    url: `/non_working_days?startDate[$lte]=${now}&endDate[$gte]=${now}`,
    method: 'GET',
    enabled: true,
  });
  const isWorkingDays = nonWorkingDays?.total === 0;

  // nav
  const navToGkeeper = useCallback(() => {
    navigate(`/features/GKeeper`);
  }, [navigate]);

  const openAppStore = useCallback(() => {
    const phoneNubmer = decrypt(localStorage.getItem('ph') ?? '');
    const deepLinkrResult = generateDeepLinkWithPhoneNumber(phoneNubmer);

    window.open(deepLinkrResult.clickURL);
  }, []);

  const isBad = result === 'bad';

  useEffect(() => {
    let timeoutRef = null;
    // depends on AF_SMART_SCRIPT, which is loaded asynchronously in the html,
    // so we need to loop through it to check for its existence
    const displayQrcode = () => {
      if (window?.AF_SMART_SCRIPT == null) {
        timeoutRef = setTimeout(displayQrcode, 100);
        return;
      }

      const phoneNubmer = decrypt(localStorage.getItem('ph') ?? '');
      const deepLinkrResult = generateDeepLinkWithPhoneNumber(phoneNubmer);

      const qrcodeId = 'download-app-qrcode';
      const qrcodeEle = document.getElementById(qrcodeId);
      if (document.getElementById(qrcodeId) != null) {
        // When hot updating in development mode,
        // it will be re-rendered,
        // so when adding the qrcode before trying to remove the child nodes.
        qrcodeEle?.replaceChildren?.([]);
        deepLinkrResult.displayQrCode(qrcodeId);
      }
    };

    timeoutRef = setTimeout(displayQrcode, 100);

    return () => {
      if (timeoutRef != null) {
        clearTimeout(timeoutRef);
      }
    };
  }, []);

  const renderResult = useMemo(() => {
    const isGood = result === 'very_good';
    switch (result) {
      case 'very_good':
      case 'maybe':
        return (
          <>
            <Typography
              size="h4"
              weight="medium"
              className={styles.workDayText}
            >
              {t(
                isWorkingDays
                  ? 'application.resultPage.titleIsWorkingDay'
                  : 'application.resultPage.titleIsNotWorkingDay'
              )}
            </Typography>
            <Spacer size="5" />
            <Typography size="h1" weight="medium" wrap={true} align="center">
              {t(
                isGood
                  ? 'application.resultPage.goodUser'
                  : 'application.resultPage.maybeUser'
              )}
            </Typography>
            <Spacer size="3" />
            <Typography
              size="body1"
              weight="normal"
              wrap={true}
              align="center"
              className={styles.maxWidthLimit}
            >
              {t(
                isGood
                  ? 'application.resultPage.goodUserDescription'
                  : 'application.resultPage.maybeUserDescription'
              )}
            </Typography>
            <Spacer size="8" />
            <Typography size="h3" weight="medium" color="body">
              {t('application.resultPage.progressBarTitle')}
            </Typography>
            <Spacer size="5" />
            <ProgressSteps />

            <div className={styles.divider} />
            <div id="download-app-qrcode" className={styles.qrcode} />

            <Typography size="h2" weight="medium" color="title">
              {t('application.resultPage.applyViaApp')}
            </Typography>

            <div className={styles.cellContainer}>
              {FeatList.map((item) => (
                <FeatItem
                  {...item}
                  key={item.alt}
                  title={t(item.title)}
                  detail={t(item.detail)}
                />
              ))}
            </div>

            {/* Hovering donwload button only in mobile interface */}
            <Button.Primary
              fullSize="lg"
              onClick={openAppStore}
              className={clss(styles.getAppButton, styles.floatButton)}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  size="h4"
                  weight="medium"
                  color="white"
                  wrap={false}
                >
                  {t('application.resultPage.continueInApp')}
                </Typography>
                <img
                  src={appleIcon}
                  alt="open-app-apple"
                  className={styles.withMarginRight}
                />
                <img src={googlePlayIcon} alt="open-app-google-play" />
              </Stack>
            </Button.Primary>
          </>
        );
      case 'bad':
      default:
        return (
          <>
            <Stack direction="column" alignItems="flex-start">
              <Typography size="h2" align="left" weight="medium" wrap={true}>
                {t('application.resultPage.badUser')}
              </Typography>
              <Spacer size="3" />
              <Typography
                size="title1"
                align="left"
                weight="normal"
                wrap={true}
                color="body"
              >
                {t('application.resultPage.badUserDescription1')}
                <Typography size="title1" weight="normal" color="link">
                  {t('application.resultPage.badUserDescription2')}
                </Typography>
                {t('application.resultPage.badUserDescription3')}
              </Typography>
              <div className={styles.innerCard}>
                <Typography
                  size="h1"
                  align="center"
                  weight="medium"
                  className={styles.exploreTitle}
                >
                  {t('application.resultPage.exploreOther')}
                </Typography>
                <Spacer size={7} />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                  wrap
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography size="title1" weight="medium" color="title">
                      {t('application.resultPage.dealsTitle')}
                    </Typography>
                    <Spacer size={1} />
                    <Typography size="body3" weight="normal" color="body">
                      {t('application.resultPage.dealsDescription')}
                    </Typography>
                    <Spacer size={6} />
                    {i18n.language === 'zh' ? (
                      <img
                        src={cardEngImg}
                        alt="cardEngImg"
                        className={styles.imgSerice}
                      />
                    ) : (
                      <img
                        src={cardZhImg}
                        alt="cardZhImg"
                        className={styles.imgSerice}
                      />
                    )}
                  </Stack>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography size="title1" weight="medium" color="title">
                      {t('application.resultPage.creditCardManagerTitle')}
                    </Typography>
                    <Spacer size={1} />
                    <Typography size="body3" weight="normal" color="body">
                      {t('application.resultPage.creditCardManagerDescription')}
                    </Typography>
                    <Spacer size={6} />
                    {i18n.language === 'zh' ? (
                      <img
                        src={perkEnImg}
                        alt="perkEnImg"
                        className={styles.imgSerice}
                      />
                    ) : (
                      <img
                        src={perkZhImg}
                        alt="perkZhImg"
                        className={styles.imgSerice}
                      />
                    )}
                  </Stack>
                </Stack>
              </div>
            </Stack>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              fullWidth={true}
              spacing={5}
            >
              <Button.Primary
                fullSize="lg"
                onClick={openAppStore}
                className={styles.getAppButton}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    size="h4"
                    weight="medium"
                    color="white"
                    wrap={false}
                  >
                    {t('application.resultPage.getAppButton')}
                  </Typography>
                  <img
                    src={appleIcon}
                    alt="open-app-apple"
                    className={styles.withMarginRight}
                  />
                  <img src={googlePlayIcon} alt="open-app-google-play" />
                </Stack>
              </Button.Primary>
              <Button.Ghost onClick={navToGkeeper}>
                <Typography size="h4" weight="medium">
                  {t('application.resultPage.knowMore')}
                </Typography>
              </Button.Ghost>
            </Stack>
          </>
        );
    }
  }, [result, t, isWorkingDays, i18n.language, navToGkeeper, openAppStore]);

  return (
    <div className={styles.container}>
      <div
        className={clss(styles.card, {
          [styles.narrowerCard]: isBad,
        })}
      >
        {renderResult}
      </div>
      {!isBad && <Faq rowClassName={styles.gap} />}
    </div>
  );
};

export default EligibilityResult;
