import React from 'react';
import clss from 'classnames';
import Stack from '../stack';
import Typography from '../typography';

import styles from './styles.module.scss';

// type: primary, hide
// heading: string
// body: string | undefined
// icon: icon url | undefined
function Alert({
  className,
  type = 'primary',
  heading = '',
  body = undefined,
  icon = undefined,
}) {
  return (
    <div className={clss(styles.alert, styles[`alert-${type}`], className)}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={5}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Typography size="title1" weight="medium" color="title" wrap>
            {heading}
          </Typography>
          {!!body && (
            <Typography size="body2" weight="normal" color="body" wrap>
              {body}
            </Typography>
          )}
        </Stack>
        {!!icon && (
          <img alt="alert-icon" className={styles.alertIcon} src={icon} />
        )}
      </Stack>
    </div>
  );
}

export default Alert;
