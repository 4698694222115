import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

// main server
const serverInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVR_API_URL,
});

// auth server
const authInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL,
});

// by server
const instanceMap = {
  main: serverInstance,
  auth: authInstance,
};

const useAxios = ({ url, method = 'GET', enabled = true, server = 'main' }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(enabled);
  const [error, setError] = useState(null);

  const fetchData = useCallback(
    async (config) => {
      setIsLoading(true);
      try {
        const instance = instanceMap[server] ?? axios;
        const response = await instance({
          url,
          method,
          ...config,
        });
        setData(response.data);
        return response;
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [url, method, server]
  );

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { data, isLoading, error, mutate: fetchData };
};

export default useAxios;
