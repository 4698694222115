import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import { useTranslation } from 'react-i18next';
import SEO from '../../SEO';
import { Col, Container, Row } from 'react-bootstrap';
import './deals.css';
import Button from 'react-bootstrap/Button';
import Spiner from '../../components/spinner/Spiner';
import Deal from '../deal/Deal';

const Deals = () => {
    const { language } = useContext(LanguageContext);
    const lang = language && language === 'zh' ? 'zh-Hant-HK' : 'en';
    const { t } = useTranslation();

    const [deals, setDeals] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [loading, setLoading] = useState(false);
    const [dealCategories, setDealCategories] = useState(new Set());

    const getDeals = useCallback(async () => {
        setLoading(true);
        await fetch(`${process.env.REACT_APP_CMS_APP_URL}/api/deals?locale=${lang}&sort=id:DESC&pagination[pageSize]=100`)
        .then(response => response.json())
        .then(data => {
            setDeals(data.data.filter(deal => Date.parse(`${deal.attributes.dealExpiryDate}T23:59:59`) >= Date.now()));
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        });
    }, [lang]);

    useEffect(() => {
        getDeals();
    }, [getDeals]);

    useEffect(() => {
        setDealCategories(new Set().add(lang === 'zh-Hant-HK' ? '所有' : 'All'));
        deals.forEach(deal => {
            setDealCategories(prev => new Set(prev).add(deal.attributes.category));
        });
        setSelectedCategory(lang === 'zh-Hant-HK' ? '所有' : 'All');
    }, [lang, deals]);

    useEffect(() => {
        deals.forEach(deal => setDealCategories(prev => new Set(prev).add(deal.attributes.category)));
    }, [deals, selectedCategory]);

    return (
        <>
            <SEO title={t('deals.pageTitle')} description={t('deals.metaDescription')} />
            <Container>
                <Row className="deals-margin">
                    <Col lg={12} className="deals">
                        <Row>
                            <Col sm={12}>
                                <h1>{t('deals.pageTitle')}</h1>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12}>
                        <div className="dealCategory">
                            {dealCategories && dealCategories.size > 0 &&
                            Array.from(dealCategories).map(category => {
                                return (
                                    <Button 
                                        variant="outline-primary" 
                                        className="mx-2 my-3 categoryButton" 
                                        key={category}
                                        active={selectedCategory === category}
                                        onClick={() => setSelectedCategory(category)}>{category}</Button>
                                )
                            })}
                        </div>                        
                    </Col>
                    <Col md={12}>
                    {loading ? <div style={{'marginTop': '-150px'}}> <Spiner /> </div> : 
                        <div className="dealsList">
                            <Row>
                                {selectedCategory === t('deals.allCategory') ? deals.map(deal => (
                                    <Deal deal={deal} key={deal.id} isSelected={false} />
                                )) :
                                 deals.filter(deal => deal.attributes.category === selectedCategory).map(deal => (
                                    <Deal deal={deal} key={deal.id} isSelected={false} />
                                 ))   
                                }
                            </Row>
                        </div>
                    }
                    </Col>
                </Row>
            </Container>
        </>
    )
};

export default Deals;