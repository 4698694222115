import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export default function SEO({ title, description, image }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={
          image || 'https://grantit.io/static/media/grantit.2dbb35f2.png'
        }
      />
    </Helmet>
  );
}

export function BlogSEO({ metaTitle, metaDescription, thumbnailImgLink }) {
  const { t } = useTranslation();
  const fullTitle = `${metaTitle} | Grantit | ${t('blogDetails.title')}`;
  const previewImage =
    thumbnailImgLink || 'https://grantit.io/static/media/grantit.2dbb35f2.png';
  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="description" content={metaDescription} />
      {/* og */}
      <meta property="og:site_name" content="Grantit" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:image" content={previewImage} />
      {/* twitter */}
      <meta property="twitter:title" content={fullTitle} />
      <meta property="twitter:description" content={metaDescription} />
      <meta property="twitter:image" content={previewImage} />
      <meta property="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}
