import React, { useEffect } from 'react';
import './thankumodal.css';
import {Modal, CloseButton, Container, Row, Col} from 'react-bootstrap';
import logo_horizontal_white from '../navbar/images/logo_horizontal_white.svg';
import appleImg from '../../pages/home/images/appleImg.svg';
import googleImg from '../../pages/home/images/googleImg.svg';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

function ThankuModal({show, onHide, smallScreen, link}) {
  const { t } = useTranslation();

  const redirectLink = () => {
    window.open(link.clickURL);
  }

  useEffect(() => {
    let timeoutRef = null;
    // depends on AF_SMART_SCRIPT, which is loaded asynchronously in the html,
    // so we need to loop through it to check for its existence
    const displayQrcode = () => {
      if (window?.AF_SMART_SCRIPT == null) {
        timeoutRef = setTimeout(displayQrcode, 100);
        return;
      }

      const qrcodeId = 'download-app-qrcode';
      const qrcodeEle = document.getElementById(qrcodeId);
      if (document.getElementById(qrcodeId) != null) {
        // When hot updating in development mode,
        // it will be re-rendered,
        // so when adding the qrcode before trying to remove the child nodes.
        qrcodeEle?.replaceChildren?.([]);
        window.AF_SMART_SCRIPT.displayQrCode(qrcodeId, {
          codeColor: "#00345A"
        });
      }
    };

    if (link?.clickURL !== null) {
      timeoutRef = setTimeout(displayQrcode, 100);
    }

    return () => {
      if (timeoutRef != null) {
        clearTimeout(timeoutRef);
      }
    };
  }, [link]);

    return (
        <>
        <Modal
        show = {show}
        size="lg-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen={smallScreen}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
          <h2 className="modal-title mt-2">{t('signUp.thanku')}</h2>
          </Modal.Title>
          <CloseButton className="modal-close" onClick = {onHide}/>
        </Modal.Header>
        <Modal.Body className="modelBody">
          <p className="model-para">
          <ReactMarkdown children={t('signUp.thankuDesc')} />
          </p>
          <div id="download-app-qrcode" className="model-download-app" />
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Container>
            <Row className="justify-content-center">
          <div className="modalfooterDiv ">
              <Col lg={4} md={6} sm={12} className="logosection d-flex justify-content-center ">
          <img src={logo_horizontal_white} alt="logo_horizontal_white" />
          </Col>
          <Col lg={8} md={6} sm={12} className="d-flex justify-content-center buttonsection ">
          <div className="d-flex">
          <div onClick={() => {redirectLink('apple')}}  >
                    <img className="apple-icon" src={appleImg} alt="appleIcon" />   
                  </div>
                  <div className="mx-2" onClick={() => {redirectLink('google')}}  >
                    <img className="apple-icon" src={googleImg} alt="playstore" />
                  </div>
                </div>
          </Col>
          </div>
          </Row>
          </Container>
        </Modal.Footer>
      </Modal>
      </>
    )
}

export default ThankuModal
