import React, { useEffect, useState, useCallback, useContext } from 'react';
// import ReactGA from 'react-ga';
import './blogdetail.css';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import GetStarted from '../../components/getstarted/GetStarted';
import ReactMarkdown from 'react-markdown';
import { LanguageContext } from '../../context/LanguageContext';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import pick from 'lodash/pick';
import { BlogSEO } from '../../SEO';

const toDetail = (attributes) => {
  return pick(attributes, [
    'category',
    'title',
    'detail',
    'bannerImgLink',
    'thumbnailImgLink',
    'publishDate',
    'imgCaption',
    'metaTitle',
  ]);
};

function BlogDetails() {
  const location = useLocation();
  // const href = window.location.href;
  const { state } = location;
  const { language } = useContext(LanguageContext);
  const lang = language === 'zh' ? 'zh-Hant-HK' : 'en';
  const [blogDetail, setBlogDetail] = useState({});

  const getBlogs = useCallback(async () => {
    const langParam = state && state.slugRedirect ? 'all' : lang;
    // add filter by slug
    await fetch(
      `${process.env.REACT_APP_CMS_APP_URL}/api/blogs?filters[slug][$eq]=${state.slugRedirect}&locale=${langParam}`
    )
      .then((response) => response.json())
      .then((data) => {
        const targetBlog = data.data?.[0] ?? {};
        const attributes = targetBlog.attributes;
        setBlogDetail(toDetail(attributes));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [lang, location, state]);

  useEffect(() => {
    if (state) {
      if (state.attributes) {
        setBlogDetail(toDetail(state.attributes));
      } else if (state.slugRedirect) {
        getBlogs();
      }
    }
  }, [state, getBlogs]);

  // useEffect(() => {
  //     const { pathname } = location;
  //     ReactGA.set({ page: pathname });
  //     ReactGA.pageview(pathname);
  //   }, [location]);
  const metaTitle = (`${blogDetail.metaTitle ?? blogDetail.title ?? 'Blog'}`).trim()
  return (
    <>
      <BlogSEO
        metaTitle={metaTitle}
        metaDescription={blogDetail.metaDescription}
        thumbnailImgLink={blogDetail.thumbnailImgLink}
      />
      <Container>
        <Row className="promotions-margin">
          <Col lg={12} className="promotions">
            <Row>
              <Col md={9} sm={12}>
                <h5 className="detailCategory">{blogDetail.category}</h5>
                <p className="detailTitle">{blogDetail.title}</p>
                <p className="detailPublish">{blogDetail.publishDate}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={12}>
            <div className="detailImg">
              <img src={`${blogDetail.bannerImgLink}`} alt={blogDetail.imgCaption} className="blogImg" />
            </div>
            <div className="blogDetails">
              <ReactMarkdown children={blogDetail.detail} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}/>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="mt-5">
        <GetStarted />
      </div>
    </>
  )
}

export default BlogDetails
