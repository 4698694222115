import React, {useState, useContext} from 'react';
import './header.css';
import {Container, Row, Col} from 'react-bootstrap';
import apple from '../../../components/navbar/images/apple.svg';
import google_play from '../../../components/navbar/images/google_play.svg';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../../context/LanguageContext';

function Header() {
    const [isScrolled, setIsScrolled] = useState(false);
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);

    window.onscroll = () => {
          setIsScrolled(window.pageYOffset === 0 ? false : true);
        return () => (window.onscroll = null);
      };
      let selectWidth = language === 'zh' ? '288px' : '220px';

  return (
      <Container fluid>
      <Row>
        <nav className={isScrolled ?  "landingNav scrolled" : "landingNav"} >
      <Col xs={6} className="my-auto">
        <div className="landingLogoDiv">
        <p className={isScrolled ?  "landingLogo scrolledlogo" : "landingLogo"}>{t('landing.logo')}</p>
        <p className={isScrolled ?  "logoDesc scrolledlogo" : "logoDesc"}>{t('landing.logoDesc')}</p>
        </div>
        </Col>
      <Col xs={6} className="my-auto d-flex justify-content-end">
          <div  className="comingSoon d-flex justify-content-center" style={{ width: `${selectWidth}` }}>
              <div className="my-auto mx-3">
              <h5 className="coming">{t('landing.commingSoon')}</h5>
              </div>
              <div className="d-flex justify-content-center my-auto mx-2 landingIcons">
              <img width="25px" height="25px" src={apple} alt="apple" />
              <img className="mx-1" width="25px" height="25px" src={google_play} alt="apple" />
              </div>
          </div>
      </Col>
      </nav>
      </Row>
      </Container>
  )
}

export default Header;
