import React, { useState, useEffect, useContext, useCallback } from 'react';
// import { useLocation } from 'react-router-dom';
// import ReactGA from 'react-ga';
import './joinus.css';
import { Container, Row, Col } from 'react-bootstrap';
import joinus from './images/joinus.png';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Spiner from '../../components/spinner/Spiner';
import Opportunity from '../../components/opportunity/Opportunity';
import {LanguageContext} from '../../context/LanguageContext';
import SEO from '../../SEO';

function JoinUs() {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const [opportunites, setOpportunities] = useState([]);
    const [loading, setLoading] = useState(false);
    const lang = language && language === 'zh' ? 'zh-Hant-HK' : 'en';

    const getOpportunites = useCallback(async () => {
        setLoading(true);
        await fetch(`${process.env.REACT_APP_CMS_APP_URL}/api/opportunities?locale=${lang}&sort=id:DESC`)
            .then(response => response.json())
            .then(data => {
                setOpportunities(data.data);
                setLoading(false);
            }).catch((err) => {
                console.log(err);
            });
        }, [lang]
    );

    useEffect(() => {
        getOpportunites();
    }, [lang, getOpportunites]);

    // let location = useLocation();

    // useEffect(() => {
    //   const { pathname } = location;
    //   ReactGA.set({ page: pathname });
    //   ReactGA.pageview(pathname);
    // }, [location]);
   
    return (
        <>
            <SEO title={t('joinUs.pageTitle')} description={t('joinUs.metaDescription')} />
            <Container fluid className="p-0 overflow-hidden ">
                <Row className="joinus-margin justify-content-center ">
                    <Col md={9} sm={12} data-aos='fade' data-aos-duration="500">
                        <h1 className="joinus-title">{t('joinUs.title')}</h1>
                        <p className="joinus-desc">{t('joinUs.desc')}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={5} sm={12} className='joinleft' data-aos='slide-right' data-aos-duration="500">
                        <img src={joinus} alt='joinus' className='joinus' />
                    </Col>
                    <Col md={7} sm={12} className='my-auto joinright' data-aos='fade' data-aos-duration="500">
                        <Row className='our-team-sm' >
                            <Col md={8} >
                                <h2 className='our-team'>{t('joinUs.ourTeam')}</h2>
                                <p className='out-team-desc'><ReactMarkdown children={t('joinUs.ourTeamDesc')} /></p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="justify-content-center join-linked-area">
                    <Col md={9} sm={12}  data-aos='fade' data-aos-duration="500">
                        <h2 className='our-team'>{t('joinUs.opportunities')}</h2>
                        <p className='opportunity-desc'><ReactMarkdown children={t('joinUs.opportunitiesDesc')} /></p>
                    </Col>
                    {loading ? <div style={{ 'marginTop': '-150px' }}> <Spiner /> </div> :
                        <div className="opportunitylist">
                            <Row className="justify-content-center">
                                {opportunites && opportunites.map(opportunity => (
                                    <Opportunity opportunity={opportunity} key={opportunity.id} />
                                ))
                                }
                            </Row>
                        </div>
                    }
                </Row>
            </Container>
        </>
    )
}

export default JoinUs
