import React, { useContext } from 'react'
import Slider from "react-slick";
import Card from '../cardcomponent/Card';
import man1 from './images/man1.svg';
import man2 from './images/man2.svg';
import man3 from './images/man3.svg';
import man4 from './images/man4.svg';
import woman1 from './images/woman1.svg';
import woman2 from './images/woman2.svg';
import woman3 from './images/woman3.svg';
import arrow_left from './images/arrow_light_grey_left.svg';
import arrow_right from './images/arrow_light_grey_right.svg';
import star from './images/star.svg';
import star1 from './images/star1.svg';
import './cardcarousel.css';
import { withTranslation } from 'react-i18next';
import {LanguageContext} from '../../context/LanguageContext';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", top: "120%", right: "47%" }}
        onClick={onClick}>
        <img src={arrow_right} alt=""/>
      </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", top: "120%", left: "47%"}}
        onClick={onClick}
      >
        <img src={arrow_left} alt=""/>
      </div>
    );
  }

const CardCarousel = (props) => {
  
      const { t } = props;
      const { language } = useContext(LanguageContext);

      const settings = {
          className: "center",
          centerMode: true,
          infinite: true,
          slidesToShow: 3,
          speed: 500,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
          responsive: [
            {
              breakpoint: 2565,
              settings: {
                centerPadding: "420px"
              }
            },
            {
              breakpoint: 1925,
              settings: {
                centerPadding: "340px",
              }
            },
            {
              breakpoint: 1600,
              settings: {
                centerPadding: "200px",
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };

      return (
            <div className="slick" data-aos='fade' data-aos-duration="500" >
            <Slider {...settings}>
              <div >
                <Card 
                title={t('home.customer.review1Title')}
                desc={t('home.customer.review1Cmnt')}
                img={man1}
                name={t('home.customer.name1')}
                star1={star}
                star2={star}
                star3={star}
                star4={star}
                star5={star1}
                />
              </div>
              <div  >
                <Card 
                title={t('home.customer.review2Title')}
                desc={t('home.customer.review2Cmnt')}
                img={man2}
                name={t('home.customer.name2')}
                star1={star}
                star2={star}
                star3={star}
                star4={star}
                star5={star}
                />
              </div>
              <div  >
                <Card 
                 title={t('home.customer.review3Title')}
                 desc={t('home.customer.review3Cmnt')}
                img={language === 'zh' ? woman1 : man3}
                name={t('home.customer.name3')}
                star1={star}
                star2={star}
                star3={star}
                star4={star}
                star5={star1}
                />
              </div>
              <div >
                <Card 
                title={t('home.customer.review4Title')}
                desc={t('home.customer.review4Cmnt')}
                img={language === 'zh' ? woman2 : woman1}
                name={t('home.customer.name4')}
                star1={star}
                star2={star}
                star3={star}
                star4={star}
                star5={star1}
                />
              </div>
              <div  >
                <Card 
                title={t('home.customer.review5Title')}
                desc={t('home.customer.review5Cmnt')}
                img={man4}
                name={t('home.customer.name5')}
                star1={star}
                star2={star}
                star3={star}
                star4={star}
                star5={star}
                />
              </div>
              { language === 'zh' &&
                <div>
                  <Card
                    title={t('home.customer.review6Title')}
                    desc={t('home.customer.review6Cmnt')}
                    img={woman3}
                    name={t('home.customer.name6')}
                    star1={star}
                    star2={star}
                    star3={star}
                    star4={star}
                    star5={star}
                    />  
                </div>
              }             
            </Slider>
            </div>
        );
}

export default withTranslation()(CardCarousel)
