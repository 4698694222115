import React, { Fragment } from 'react';
import clss from 'classnames';
import styles from './styles.module.scss';
import Typography from '../typography';
import checkmarkIcon from 'asset/icons/checkmark.webp';

// index: number
// children: string
// active: bool
function Node({ id, children, active, pass = false }) {
  return (
    <div className={styles.node}>
      {!pass ? (
        <div
          className={clss(styles.tag, {
            [styles.active]: active && !pass,
          })}
        >
          <Typography xlSize="h4" lgSize="title1" weight="medium" color="white">
            {id}
          </Typography>
        </div>
      ) : (
        <img
          alt="step-checkmark"
          className={styles.checkmark}
          src={checkmarkIcon}
        />
      )}
      <div className={clss({ [styles.textPass]: pass })}>
        <Typography
          className
          xlSize="h4"
          lgSize="title1"
          weight="medium"
          color={active && !pass ? 'title' : 'inactive'}
        >
          {children}
        </Typography>
      </div>
    </div>
  );
}

// activeIndex: number
// list: string[]
function Steps({ activeIndex, list }) {
  return (
    <div className={styles.steps}>
      {list.map((item, index, array) => (
        <Fragment key={index}>
          <Node
            id={index + 1}
            active={activeIndex === index}
            pass={index < activeIndex}
          >
            {item}
          </Node>
          {index < array.length - 1 && (
            <span
              className={clss(styles.separator, {
                [styles.pass]: index < activeIndex,
              })}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
}

export default Steps;
