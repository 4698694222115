import React from 'react';
import clss from 'classnames';
import styles from './styles.module.scss';

// lgSize: >= 1200px, h1, h2, h3, h4, title1, title2, body1, body2, body3
// xlSize: < 1200px, h1, h2, h3, h4, title1, title2, body1, body2, body3
// size: h1, h2, h3, h4, title1, title2, body1, body2, body3
// weight: normal, medium, semibold, bold
// align: left, center, right
// color: heading, title, body, white, inactive, error, tip, link
// wrap: bool
function Typography({
  wrap = false,
  size = 'body1',
  weight = 'normal',
  align = undefined,
  xlSize = undefined,
  lgSize = undefined,
  color = undefined,
  children,
  ...props
}) {
  const className = clss(
    styles.text,
    styles[`size-${xlSize ?? size}`],
    styles[`weight-${weight}`],
    {
      [styles[`color-${color}`]]: !!color,
      [styles[`size-lg-${lgSize}`]]: !!lgSize,
      [styles[`align-${align}`]]: !!align,
      [styles.wrap]: !!wrap,
      [styles.nowrap]: !wrap,
    },
    props.className
  );

  if (size === 'h1') {
    return <h1 className={className}>{children}</h1>;
  }

  if (size === 'h2') {
    return <h2 className={className}>{children}</h2>;
  }

  if (size === 'h3') {
    return <h3 className={className}>{children}</h3>;
  }

  if (size === 'h4') {
    return <h4 className={className}>{children}</h4>;
  }

  return <span className={className}>{children}</span>;
}

export default Typography;
