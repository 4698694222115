import React, { useEffect, useRef, useState } from 'react';
import "./step2.css";
import { Col } from 'react-bootstrap';
import property from './images/property.svg';
import car from './images/car.svg';
import married from './images/married.svg';
import retirement from './images/retirement.svg';
import travel from './images/travel.svg';
// import rightArrow from './images/rightArrow.svg';
import Goal from '../../goal/Goal';
import { useTranslation } from 'react-i18next';


function Step2({ handleNext, handleFormData, values, step2Clear }) {
    const step2Ref = useRef();
    const [bothInput, setBothInput] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        step2Clear && bothInput ? step2Ref.current.style.background = "linear-gradient(to left ,rgba(0, 52, 90, 1),rgba(51, 103, 141, 1))" : step2Ref.current.style.background = "#C0C3C6";

    }, [step2Clear, bothInput]);

    return (
        <>
            <h5 className="lifeGoal">{t('home.calculator.selectGoals')}</h5>
            <div className="goalsForm">
                <Col lg={2} className="d-flex justify-content-center goalTab">
                    <Goal
                        Img={property}
                        goal={t('home.calculator.propertyPayment')}
                        amount="propertyAmount"
                        timeLine="propertyTimeline"
                        amountValue={values.propertyAmount}
                        timelineValue={values.propertyTimeline}
                        handleFormData={handleFormData}
                        setBothInput={setBothInput}
                    />
                </Col>
                <Col lg={2} className="d-flex justify-content-center goalTab">
                    <Goal
                        Img={car}
                        goal={t('home.calculator.buyCar')}
                        amount="carAmount"
                        timeLine="carTimeline"
                        amountValue={values.carAmount}
                        timelineValue={values.carTimeline}
                        handleFormData={handleFormData}
                        setBothInput={setBothInput}
                    />
                </Col>
                <Col lg={2} className="d-flex justify-content-center goalTab">
                    <Goal
                        Img={married}
                        goal={t('home.calculator.gettingMarried')}
                        amount="marriageAmount"
                        timeLine="marriageTimeline"
                        amountValue={values.marriageAmount}
                        timelineValue={values.marriageTimeline}
                        handleFormData={handleFormData}
                        setBothInput={setBothInput}
                    />
                </Col>
                <Col lg={2} className="d-flex justify-content-center goalTab">
                    <Goal
                        Img={travel}
                        goal={t('home.calculator.travelWorld')}
                        amount="travelAmount"
                        timeLine="travelTimeline"
                        amountValue={values.travelAmount}
                        timelineValue={values.travelTimeline}
                        handleFormData={handleFormData}
                        setBothInput={setBothInput}
                    />
                </Col>
                <Col lg={2} className="d-flex justify-content-center goalTab">
                    <Goal
                        Img={retirement}
                        goal={t('home.calculator.earlyRetirement')}
                        amount="retirementAmount"
                        timeLine="retirementTimeline"
                        amountValue={values.retirementAmount}
                        timelineValue={values.retirementTimeline}
                        handleFormData={handleFormData}
                        setBothInput={setBothInput}
                    />
                </Col>
            </div>
            <Col lg={12} className="d-flex justify-content-start">
                <div className="nextStep">
                    <div onClick={step2Clear && bothInput === true ? handleNext : undefined} ref={step2Ref} className="next">{t('home.calculator.next')}</div>
                </div>
            </Col>
        </>
    )
}

export default Step2

