import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import arrowUp from '../../components/gkeeperfaq/images/arrowSecondUp.svg';
import arrowDown from '../../components/gkeeperfaq/images/arrowSecondDown.svg';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';


function Repayment() {
    const [show, setShow] = useState('');
    const { t } = useTranslation();

    const handleShow = (action) => {
        if (action === 'method' || 'early' || 'bill' || 'due' || 'fail' || 'more') {
            setShow((prev) => prev === action ? '' : action);
        }
    }

    return (
        <>
            <Col style={{ 'marginTop': '38px' }}>
                <div className="faqDivider"></div>
                <div className="help-container " >
                <div  className="d-flex justify-content-between" onClick={() => handleShow('method')}>
                        <h4 style={show === 'method' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.repaymentTab.method')}</h4>
                        <img  src={show === 'method' ? arrowDown : arrowUp} alt="plus" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'method' &&
                    <div >
                        <p className="faq-desc"><ReactMarkdown children={t('faq.repaymentTab.methodDesc')} /></p>
                    </div>
                }
            </Col>
            <Col>
                <div className="faqDivider"></div>
                <div className="help-container " >
                <div  className="d-flex justify-content-between" onClick={() => handleShow('early')}>
                        <h4 style={show === 'early' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.repaymentTab.early')}</h4>
                        <img  src={show === 'early' ? arrowDown : arrowUp} alt="plus" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'early' &&
                    <div >
                        <p className="faq-desc"><ReactMarkdown children={t('faq.repaymentTab.earlyDesc')} /></p>
                    </div>
                }
            </Col>
            <Col>
                <div className="faqDivider"></div>
                <div className="help-container " >
                <div  className="d-flex justify-content-between" onClick={() => handleShow('bill')}>
                        <h4 style={show === 'bill' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.repaymentTab.bill')}</h4>
                        <img  src={show === 'bill' ? arrowDown : arrowUp} alt="plus" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'bill' &&
                    <div >
                        <p className="faq-desc"><ReactMarkdown children={t('faq.repaymentTab.billDesc')} /></p>
                    </div>
                }
            </Col>
            <Col>
                <div className="faqDivider"></div>
                <div className="help-container " >
                <div  className="d-flex justify-content-between" onClick={() => handleShow('due')}>
                        <h4 style={show === 'due' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.repaymentTab.due')}</h4>
                        <img  src={show === 'due' ? arrowDown : arrowUp} alt="plus" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'due' &&
                    <div >
                        <p className="faq-desc"><ReactMarkdown children={t('faq.repaymentTab.dueDesc')} /></p>
                    </div>
                }
            </Col>
            <Col>
                <div className="faqDivider"></div>
                <div className="help-container ">
                <div  className="d-flex justify-content-between" onClick={() => handleShow('fail')}>
                        <h4 style={show === 'fail' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.repaymentTab.fail')}</h4>
                        <img  src={show === 'fail' ? arrowDown : arrowUp} alt="plus" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'fail' &&
                    <div >
                        <p className="faq-desc"><ReactMarkdown children={t('faq.repaymentTab.failDesc')} /></p>
                    </div>
                }
            </Col>
            <Col>
                <div className="faqDivider"></div>
                <div className="help-container">
                <div  className="d-flex justify-content-between" onClick={() => handleShow('more')}>
                        <h4 style={show === 'more' ? {'color': '#00345A'} : {'color': '#99B3C6'}} className="faqQuestionC">{t('faq.repaymentTab.more')}</h4>
                        <img  src={show === 'more' ? arrowDown : arrowUp} alt="plus" className="cursor mx-2" />
                    </div>
                </div>
                {show === 'more' &&
                    <div >
                        <p className="faq-desc"><ReactMarkdown children={t('faq.repaymentTab.moreDesc')} /></p>
                    </div>
                }
            </Col>
            <div className="faqDivider"></div>
        </>
    )
}

export default Repayment
