import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getRemainingTime } from 'utils/Formatters';
import VerificationInput from 'components/form/verificationinput';
import Typography from 'components/atoms/typography';
import Button from 'components/atoms/button';
import editIcon from 'asset/icons/edit.webp';
import useAxios from 'hooks/useAxios';

import { OtpFormSchema } from './const';
import styles from './styles.module.scss';

const CAPTCHA_INTERVAL_TIME = 60;

const SendAgainLink = ({ onSendOtp = async () => {}, loading = true }) => {
  const { t } = useTranslation();
  const timeRef = useRef(null);
  const [_, refreshUI] = useState(Date.now());

  const prevSendCodeTime = parseInt(localStorage.getItem('sct'));

  // No memo, one calculation per second
  const remainingTime = isNaN(prevSendCodeTime)
    ? NaN
    : CAPTCHA_INTERVAL_TIME -
      Math.floor((Date.now() - prevSendCodeTime) / 1000);

  // Whether or not the OTP code has been sent for more than CAPTCHA_INTERVAL_TIME
  const timeExpired = !isNaN(remainingTime) && remainingTime < 0;

  useEffect(() => {
    if (timeExpired && timeRef.current != null) {
      // reset timeRef
      clearInterval(timeRef.current);
      timeRef.current = null;
    } else if (!timeExpired) {
      /**
       * When hot updating in dev mode, the interval stops working,
       * but this can be solved by resetting the Interval.
       * Processing won't be triggered too often here either, don't worry.
       */
      if (timeRef.current !== null) {
        clearInterval(timeRef.current);
      }
      timeRef.current = setInterval(() => refreshUI(Date.now()), 1000);
    }
    return () => {
      if (timeRef.current !== null) {
        clearInterval(timeRef.current);
      }
    };
  }, [timeExpired]);

  const onWaitOtpFinish = useCallback(async () => {
    await Promise.resolve(onSendOtp());
    refreshUI(Date.now());
  }, [onSendOtp]);

  if (isNaN(remainingTime)) {
    return null;
  }

  if (timeExpired) {
    return (
      <Button.Ghost loading={loading} onClick={onWaitOtpFinish}>
        <Typography size="title1" weight="normal" color="link">
          {t('application.otp.sendAgian')}
        </Typography>
      </Button.Ghost>
    );
  }

  return (
    <Typography size="title1" weight="normal" color="inactive">
      {t('application.otp.noGetCode', {
        time: getRemainingTime({
          time: remainingTime,
        }).time,
      })}
    </Typography>
  );
};

function Otp() {
  const navigate = useNavigate();
  const [userPhoneNumber] = useOutletContext();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(OtpFormSchema),
  });

  const {
    isLoading: submitFormLoading,
    error: submitFormError,
    mutate: submitEligibilityForm,
  } = useAxios({
    url: '/loan_eligibility_management',
    method: 'POST',
    enabled: false,
  });

  const {
    isLoading: sendOtpLoading,
    error: sendOtpError,
    mutate: sendOtp,
  } = useAxios({
    server: 'auth',
    url: '/otp/send',
    method: 'POST',
    enabled: false,
  });

  // safe check
  // if userPhoneNumber is null, so need check to sign up page
  useEffect(() => {
    if (userPhoneNumber == null || userPhoneNumber?.length === 0) {
      navigate('/features/cashloan/sign-up', { replace: true });
    }
  }, [userPhoneNumber, navigate]);

  const onSubmit = useCallback(
    async (data) => {
      const eligibilityData = JSON.parse(localStorage.getItem('eligibility'));
      // from index.html cdn got CryptoJS
      const cipherString = window.CryptoJS.AES.encrypt(
        JSON.stringify({
          ...eligibilityData,
          otpCode: data.otp,
          phoneNumber: userPhoneNumber,
        }),
        process.env.REACT_APP_SERVR_ELIGIBILITY_SECRET_KEY
      ).toString();
      // call submit api
      const response = await submitEligibilityForm({
        data: {
          action: 'calculateWeb',
          payload: { cipherString },
        },
      });
      if (response?.status === 201 && !!response.data.status) {
        localStorage.setItem('eligibilityResult', response.data.status);
        navigate('/features/cashloan/pre-approval/loading', { replace: true });
        return;
      }
    },
    [submitEligibilityForm, userPhoneNumber, navigate]
  );

  const onBack = useCallback(() => {
    navigate('/features/cashloan/sign-up', { replace: true });
  }, [navigate]);

  const onSendOtp = useCallback(async () => {
    const sendOtpResponse = await sendOtp({
      data: { phoneNumber: userPhoneNumber },
    });
    if (sendOtpResponse?.status === 201) {
      // send-code-time = sct
      localStorage.setItem('sct', Date.now());
    }
  }, [sendOtp, userPhoneNumber]);

  return (
    <>
      <Typography size="h1" weight="semibold" color="title">
        {t('application.otp.title')}
      </Typography>
      <Typography
        size="h3"
        weight="normal"
        color="inactive"
        wrap={true}
        align="center"
      >
        {`${t('application.otp.description1')}  +852 ${userPhoneNumber}`}
        <img
          onClick={onBack}
          alt="edit-icon"
          src={editIcon}
          className={styles.editIcon}
        />
        {`${t('application.otp.description2')}`}
      </Typography>
      <Controller
        name="otp"
        control={control}
        render={({ field }) => (
          <VerificationInput {...field} validChars={'0-9'} autoFocus={true} />
        )}
      />
      <SendAgainLink onSendOtp={onSendOtp} loading={sendOtpLoading} />
      <Button.Primary
        fullSize="lg"
        disable={!isValid}
        loading={submitFormLoading}
        className={styles.submitButton}
        onClick={handleSubmit(onSubmit)}
      >
        <Typography size="h4" weight="semibold" color="white">
          {t('application.otp.submit')}
        </Typography>
      </Button.Primary>
      {(!!sendOtpError?.message || !!submitFormError?.message) && (
        <Typography size="title2" color="error" wrap>
          {sendOtpError?.message ?? sendOtpError?.message}
        </Typography>
      )}
    </>
  );
}

export default Otp;
