import React from "react";
import Slider from "react-slick";
import { Col } from 'react-bootstrap';
import './logoslider.css';

const LogoSlider = ({ images, links, width}) => {

    const noOfSlides = width < 475 ? 2 : width < 769 ? 3 : 5;

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: noOfSlides,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 0,
        cssEase: "linear",
        adaptiveHeight: true
    };

    const handleRedirect = (link) => {
        if (link.length !== 0) {
            window.open(link, '_blank');
        }
    };

    return (
        <div className="logoslider">
            <Slider {...settings}>       
                {
                    images.map((image, index) =>
                        (
                            <Col className="d-flex justify-content-center press mt-sm-5" key={image} lg={2} xs={6} sm={6}
                                onClick={() => handleRedirect(links[index])}
                            >
                                <img  alt="background" src={image} />
                            </Col>
                        )
                    )
                }
            </Slider>
        </div>
    )

};

export default LogoSlider;